import { parseQueryString } from '@shein/common-function'
import { getCategoriesApi, getCategoriesSideApi } from 'public/src/services/api/category.js'
import { getPreloadImgs, preLoadImagesInstall } from './preloadImg.js'
import TMG from '@shein/time-management-guru'
import { setRouterCopy, getRouterCopy } from '../../utlis/index.js'
import { PRE_TIMER } from './const.js'
let isCategoryFirstQuickRequest = true
let outherPageTimer = null
let outherPageFeched = false
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'

/**
 * @description: 分类请求参数
 * @param {object} preloadParams 预加载参数, 优先级高于location.search
 * */
export const categoryRequestParams = (
  { cateType = 'cat', withI18n, channelId, oneCate } = {},
  preloadParams
) => {
  const {
    content_id,
    scene_key,
    channel_type,
    contentId,
    channelType,
    sceneKey,
  } = preloadParams || parseQueryString(location.search) || {}

  return {
    xhrJsonData: true,
    cateType,
    withI18n,
    channelId,
    oneCate,
    content_id,
    scene_key,
    channel_type,
    contentId,
    channelType,
    sceneKey,
  }
}

const getPreloadCategoryParams = (params = {}) => {
  const beforeRouter = getRouterCopy()
  if (!beforeRouter.query) {
    throw new Error('getPreloadCategoryParams: beforeRouter.query is undefined')
  }
  const { channelId, oneCate } = beforeRouter.query
  return categoryRequestParams(
    { withI18n: true, channelId, oneCate, cateType: params?.cateType },
    beforeRouter.query
  )
}

const fetchCategory = async params => {
  try {
    const result = await getCategoriesApi(params)
    return result
  } catch (err) {
    localStorage.setItem('categoryError', err)
    throw err
  }
}

const fetchSideCategory = async params => {
  try {
    const result = await getCategoriesSideApi(params)
    return result
  } catch (err) {
    localStorage.setItem('categoryError', err)
    throw err
  }
}

export const categoryRequests = {
  quickRequests: {
    getCategorys: {
      params: getPreloadCategoryParams,
      request: fetchCategory,
    },
  },
}
export const sideCategoryRequests = {
  quickRequests: {
    getCategorys: {
      params: () => getPreloadCategoryParams({ cateType: 'sidecat' }),
      request: fetchSideCategory,
    },
  },
}

export const handleCategoryRouterTriggerQuickRequestBefore = async (to, from) => {
  setRouterCopy(to)

  const IS_SSR = !from.name
  if (IS_SSR) {
    isCategoryFirstQuickRequest = false
  }

  if (isCategoryFirstQuickRequest) {
    isCategoryFirstQuickRequest = false
    TMG.triggerQuickRequests('category')
    TMG.triggerQuickRequests('sideCategory')
  }
}

/**
 * @description: 其他页面空闲时段预请求分类接口，只请求一次。
*/
export const handleCategoryRouterTriggerQuickRequestAfter = to => {
  if (outherPageFeched) return

  clearTimeout(outherPageTimer)
  // 首页、专题、个人中心
  const pageRouteNames = [
    'config_index',
    'config_activity',
    'UserIndex',
  ]
  if (!pageRouteNames.includes(to.name)) return

  outherPageTimer = setTimeout(() => {
    requestIdleCallback(() => {
      outherPageFeched = true
      fetchCategoryData()
      // 预加载资源
      if (!_gb_app_) {
        return console.error('预加载资源失败，_gb_app_ 未定义')
      }
      prefetchResource.listen({
        el: _gb_app_.$el,
        prefetchList: [
          {
            chunkName: 'category',
            relType: 'prefetch'
          }
        ],
        delay: 10, 
        prefetchCallback: ({ status, info }) => {
          console.log('category prefetchCallback', status, info)
          const loadComponent = prefetchResource.importAsyncComponent({
            chunkName: 'category',
            componentFactory: () =>
              import(/* webpackChunkName: "category" */ 'public/src/pages/category/container.vue')
          })
          loadComponent()
        },
      })
    })
  }, PRE_TIMER)
}

function fetchCategoryData() {
  if (!isCategoryFirstQuickRequest) return
  isCategoryFirstQuickRequest = false
  console.info('categoryInfo: other pages prefetch success')
  setRouterCopy({
    query: {},
  })
  TMG.triggerQuickRequests('category')
  TMG.triggerQuickRequests('sideCategory')
}

export function preloadTwoCategoryImg(twoCategory) {
  const images = getPreloadImgs({ twoCategory })
  preLoadImagesInstall({ images })
  return images
}


export const recommendPublicProductItemConfig = {
  showPromotion: true,
  showSellingPoint: true,
  showBadge: true,
  showAddBagBtn: true,
  showMultiColor: false,
  disableMainimgJump: true,
  // hideRetailPrice: false,          // 隐藏划线价格【折扣标签展示在价格旁边需求】
  hideRetailPrice: true, // 隐藏划线价
  hideDiscountBadge: true,        // 隐藏折扣角标
  showDiscountLabelAfterPrice: true, // 折扣价标展示在价格右边
  moduleFrom: 'recommend',
  style: {
    // pricesNewline: true,
  },
  showTitle: false,
  showSeriesBrand: true,          // 品牌系列
  showLocalSeller: true,
  showPlusSize: true,
  showNewFlashPriceOnSale: true,
  useOwnClickAddBagAnalysis: true,
  showRankingList: false,
}

export const extendsComplianceModeConfig = (config) => { // 合规模式特殊处理
  config.standardPrice = true
  // config.hideFlashSaleIcon = true
  // config.hideIconBeforePrice = true
  // config.showSheinClubPriceOnSale = false
  // config.showPromotion = false
  // config.showDiscountLabelAfterPrice = false
  // config.showSheinClubDiscountValue = false
  // config.showSheinClubDiscountValueAfterPrice = false
  // config.showNewDiscountLabel = false
  // config.style = { ...config.style, salePriceColor: '#000' }
}

const state = {
  goodsTabActive: 'save',
  wishlistCount: 0,
  originalWishlistCount: 0,
  scrollPos: 0,
  wishlist: {
    data: [],
    page: 1,
    noMoreData: false
  },
  mallInfo: [],
  loaded: false, // 只要请求过数据就设置为true
  abtDeactivateResult: '',
  isRouterLeaveFlag: false,
  pageReload: false, // 页面需要刷新
  dialogName: 'default', // 弹窗名字
}

const mutations = {
  assignState (state, payload) {
    Object.assign(state, payload)
  },
  deleteWishList (state) {
    state.wishlistCount--
  },
  updateAbtDeactivateResult (state, val) {
    state.abtDeactivateResult = val || ''
  },
  setPageReload (state, v) {
    state.pageReload = v
  },
  updateDialogName(state, val) {
    state.dialogName = val
  }
}

export default {
  namespaced: true,
  state,
  mutations
}

import service from './service'
import Vue from 'vue'
import TMG from '@shein/time-management-guru'
import {
  getCateCrowdInfoApi,
  getCateByChannelApi,
  getTopNavApi,
  getLeftContentApi,
  getRightContentApi,
  getRightContentBatchApi,
  getLanguageApi,
} from 'public/src/services/api/category.js'
import { categoryRequestParams, preloadTwoCategoryImg } from 'public/src/pre_requests/modules/category/index.js'
import {
  formatCategoryData,
  cateHomeStorageData,
  getInitActiveInfoIndex,
  getOneCategoryOrExpand,
  setInitCacheData,
  getOriginTwoCategoryInfo,
  getTwoCategoryData,
  getUpdateCategoryPageInit,
  handleTwoCategoryData,
  setMonitor,
} from './utils'
import { markPoint } from 'public/src/services/mark/index.js'
import { languageKey } from './const'

const bffActions = {
  // 获取多语言
  async getLanguage({ commit }) {
    const params = {
      languageKeys: languageKey
    }
    const language = await getLanguageApi(params)
    commit('assignState', {
      bffLanguages: language?.info?.result || {}
    })
  },
  // 获取顶部数据
  async getBffNavList({ commit, dispatch }, { cateType } = {}) {
    commit('assignStateByCateType', {
      cateType: 'bffCat',
      tabLoadingStatus: true, // 头部骨架屏
      oneCateLoadingStatus: true, // 左边骨架屏
      twoCateLoadingStatus: true, // 右边骨架屏
    })
    const navRes = await getTopNavApi()
    // 提交mutations
    commit('assignStateByCateType', {
      cateType: 'bffCat',
      topNav: navRes.info?.tabs || [],
      tabLoadingStatus: false, // 头部骨架屏
    })
    await dispatch('changeChannel', { channel: navRes.info?.tabs?.[0], channelIndex: 0, oneCate: 0, cateType }) // TODO: oneCate 可以从本地缓存中来
  },
  async changeChannel ({ commit, dispatch }, { channel, channelIndex, oneCate = 0, cateType }) {
    try {
      commit('assignStateByCateType', {
        cateType: 'bffCat',
        oneCateLoadingStatus: true, // 左边骨架屏
        twoCateLoadingStatus: true, // 右边骨架屏
        activeChannel: { channel, channelIndex, oneCate }
      })
      await dispatch('getLeftContent', { channel, cateType })
      await dispatch('getRightContent', { channel })
    } catch (error) {
      console.log(error)
    }
 
  },
  async getLeftContent({ commit, state, dispatch }, { channel, cateType = '' }) {
    try {
      let oneCategoryData = state.bffCat?.categoryContainer?.oneCategoryData
      // 缓存中有数据，直接取缓存数据
      if (oneCategoryData[channel.id]) {
        commit('assignStateByCateType', {
          cateType: 'bffCat',
          oneCateLoadingStatus: false, // 左边骨架屏
          oneCategory: oneCategoryData[channel.id] || {}
        })
        return
      }
      const leftContentRes = await getLeftContentApi({
        channelType: channel?.id,
        cat_id: channel?.cat_id
      })
      const leftContent = leftContentRes.info
      // 侧边栏需要 从批量接口中获取图片
      if (cateType.value === 'sidecat') {
        await dispatch('getRightContentBatch', { channel, oneCategoryData: leftContent })
        const batchContent = state.bffCat.categoryContainer?.batchTwoCategoryData[channel.id] || {}
        const values = Object.values(batchContent)
        values.length > 0 && leftContent?.contents?.forEach((item) => {
          const rightItem = values.find((rightItem) => rightItem?.firstLevelId === item.id)
          if (rightItem) {
            item.cover = rightItem.contents?.[0]?.child?.[0]?.props?.items?.[1]?.cover || {}
          }
        })
      }
      // 缓存左侧数据
      if (leftContent?.contents?.length > 0) {
        oneCategoryData[channel.id] = leftContent
      }
      commit('assignStateByCateType', {
        cateType: 'bffCat',
        oneCateLoadingStatus: false, // 左边骨架屏
        oneCategory: leftContent || {},
        categoryContainer: {
          ...state.bffCat.categoryContainer,
          oneCategoryData
        }
      })
    } catch (error) {
      console.log(error)
    }
  },
  async changeLeftContent({ commit, state, dispatch }, { oneCate }) {
    commit('assignStateByCateType', {
      cateType: 'bffCat',
      oneCateLoadingStatus: false, // 左边骨架屏
      twoCateLoadingStatus: true, // 右边骨架屏
      activeChannel: {
        ...state.bffCat.activeChannel,
        oneCate,
      },
    })
    dispatch('getRightContent', { channel: state.bffCat.activeChannel.channel, oneCate })
  },
  async getRightContent({ commit, state }, { channel = {}, oneCate = 0 }) {
    try {
      const twoCategoryData = state.bffCat.categoryContainer?.twoCategoryData
      const batchTwoCategoryData = state.bffCat.categoryContainer?.batchTwoCategoryData
      const oneCategory = state.bffCat?.oneCategory
      const cacheKey = `${channel.id}_${oneCategory?.contents[oneCate]?.id}`
      let rightContentRes = {}
      // 如果缓存中有数据，直接取缓存数据
      if (batchTwoCategoryData[channel.id]?.[oneCategory?.contents[oneCate]?.id]){
        rightContentRes.info = batchTwoCategoryData[channel.id]?.[oneCategory?.contents[oneCate]?.id]
      } else if (twoCategoryData[cacheKey]) {
        rightContentRes = twoCategoryData[cacheKey]
      } else {
        rightContentRes = await getRightContentApi({
          channelType: channel?.id,
          id: oneCategory?.contents[oneCate]?.id
        })
        if (rightContentRes?.info?.contents?.length > 0) {
          twoCategoryData[cacheKey] = rightContentRes
        }
      }
      // 处理右侧埋点数据
      const channelName = channel?.channelName
      const oneCateName = oneCategory?.contents?.[oneCate]?.firstFloorContent?.props?.metaData?.categoryName
      const language = state.bffLanguages
      service.setData({
        channel,
        oneCate: oneCategory?.contents?.[oneCate],
        userpathParams: { channelName, oneCateName, cateTitle: '' },
        catId: channel.cat_id,
      })
      handleTwoCategoryData(rightContentRes.info || [], language)
  
      commit('assignStateByCateType', {
        cateType: 'bffCat',
        twoCateLoadingStatus: false, // 右边骨架屏
        twoCategory: rightContentRes.info || [],
        categoryContainer: {
          ...state.bffCat.categoryContainer,
          twoCategoryData
        }
      })
    } catch (error) {
      console.log(error)
    }
  },
  async getRightContentBatch({ commit, state }, { channel = {}, oneCategoryData = [] }) {
    const batchTwoCategoryData = state.bffCat.categoryContainer?.batchTwoCategoryData
    try {
      const rightContentBatchRes = await getRightContentBatchApi({
        channelType: channel?.id,
        ids: oneCategoryData?.contents?.map(item => item.id)
      })
      if (Object.values(rightContentBatchRes.info)?.length > 0) {
        batchTwoCategoryData[channel.id] = rightContentBatchRes.info
      }
    } catch (error) {
      console.log(error)
    }
    commit('assignStateByCateType', {
      cateType: 'bffCat',
      twoCateLoadingStatus: false, // 右边骨架屏
      categoryContainer: {
        ...state.bffCat.categoryContainer,
        batchTwoCategoryData,
      }
    })
  }
}
const actions = {
  async getCateList({ state, dispatch }, { query = {}, cateType = '', special = false } = { }) {
    const firstGetCategoryData = state.dynamicCates.length <= 0
    if (firstGetCategoryData || special) {
      await dispatch('handleFetchCategoryData',  { query, cateType })
    } else {
      await dispatch('handleFormatCategoryData', { query, cateType })
    }
  },

  async handleFetchCategoryData(
    { state, commit },
    { query = {}, cateType = '' } = {}
  ) {
    let categorySsrData = window.categorySsrData // ssr降级 数据放在categorySsrData
    if (!categorySsrData) {
      commit('assignStateByCateType', {
        cateType,
        tabLoadingStatus: true, // 头部骨架屏
        oneCateLoadingStatus: true, // 左边骨架屏
        twoCateLoadingStatus: true, // 右边骨架屏
      })
      const params = categoryRequestParams({
        withI18n: true,
        cateType,
        channelId: query.channelId,
        oneCate: query.oneCate,
      })
      markPoint({ eventName: 'waitPageData', measureFrom: 'routeChangeEnd' })
      const preloadType = cateType === 'sidecat' ? 'sideCategory' : 'category'
      const res = await TMG.useQuickRequest(
        `${preloadType}/getCategorys`,
        params
      ).catch(err => {
        console.error(err)
      })
      markPoint({ eventName: 'pageDataReady', measureFrom: 'waitPageData' })
      categorySsrData = res?.data || {}
    }

    const dynamicCates = categorySsrData.data || []
    const { categoryData, categoryDataListByOrder } = formatCategoryData(dynamicCates)
    const currentActiveInfo = categorySsrData.currentActiveInfo || {}
    const language = categorySsrData.language || {}
    const categoryCrowdInfo = categorySsrData.categoryCrowdInfo || {}
    // const allTabAbt = categorySsrData.allTabAbt || {}
    const isAllTabAutoMap = categorySsrData.isAllTabAutoMap || 0
    const crowdChannel = categorySsrData.crowdChannel || ''
    const { channelKey, oneCategoryKey, storageType } = cateHomeStorageData({
      cateType,
      localsLang: state.locals.lang,
    })
    const { channelId: channel, oneCate, channelIndex, isNew } = currentActiveInfo

    // 处理一级分类和二级分类数据
    const rightCateWidth = state[cateType].rightCateWidth
    const {
      oneCategoryExpand,
      expandRearFirstLevel,
      expandFirstLevelId,
      oneCategory,
      twoCategory,
    } = getUpdateCategoryPageInit({
      categoryData,
      dynamicCates,
      language,
      // allTabAbt,
      locals: state.locals,
      cateExpand: state.cateExpand,
      channel, 
      oneCate,
      rightCateWidth,
      isNew
    })

    const stateData = {
      dynamicCates,
      categoryData,
      categoryDataListByOrder,
      language,
      categoryCrowdInfo,
      // allTabAbt,
      isAllTabAutoMap,
      crowdChannel,
      expandRearFirstLevel,
      expandFirstLevelId,
      categoryFreeshippingCcc: categorySsrData.categoryFreeshippingCcc,
    }
    
    // const twoCategoryData = twoCategory.filter(item => !item.parentCatNode && [2, 3, 4, 5, 6, 7].includes(+item.type))

    const cateTypeData = {
      cateType,
      initCateData: true,
      tabLoadingStatus: false,
      oneCateLoadingStatus: false,
      twoCateLoadingStatus: false,
      channelKey,
      oneCategoryKey,
      storageType,
      oneCategoryExpand,
      oneCategory,
      twoCategory,
      active: { channel, oneCate, channelIndex, isNew },
    }
    preloadTwoCategoryImg(twoCategory)

    markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
    commit('assignState', stateData)
    // commit('dealingTwoCate', { twoCategory: twoCategoryData, cateType })
    commit('assignStateByCateType', cateTypeData)
    setInitCacheData(cateTypeData)
    Vue.nextTick(() => {
      markPoint({ eventName: 'setPageDataNextTick', measureFrom: 'setPageData' })
    })
  },

  /**
   * @description 此时分类页的数据已经存在，处理当前type下的选中状态. 场景：侧边分类进入后，主的分类页直接用数据
  */
  async handleFormatCategoryData({ state, dispatch, commit }, { query = {}, cateType = '' }) {
    const { channelKey, oneCategoryKey, storageType } = cateHomeStorageData({
      cateType,
      localsLang: state.locals.lang,
    })
    // 进行更新的数据
    const { channel, oneCate, channelIndex } = await dispatch(
      'getInitActiveInfo',
      { cateType, query, channelKey, oneCategoryKey, storageType }
    )
    const { language, /* allTabAbt,*/ locals } = state
    const rightCateWidth = state[cateType].rightCateWidth
    const {
      oneCategoryExpand,
      oneCategory,
      twoCategory,
      expandRearFirstLevel,
      expandFirstLevelId,
    } = getUpdateCategoryPageInit({
      categoryData: state.categoryData,
      dynamicCates: state.dynamicCates,
      language,
      // allTabAbt,
      locals,
      cateExpand: state.cateExpand,
      channel, 
      oneCate,
      rightCateWidth,
      isNew: state.categoryData[channel].isNew
    })
    const cateTypeData = {
      cateType,
      initCateData: true,
      tabLoadingStatus: false,
      oneCateLoadingStatus: false,
      twoCateLoadingStatus: false,
      channelKey,
      oneCategoryKey,
      storageType,
      active: {
        channel,
        oneCate,
        channelIndex,
        isNew: state.categoryData[channel].isNew
      },
      oneCategoryExpand,
      oneCategory,
      twoCategory,
    }

    commit('assignState', { expandRearFirstLevel, expandFirstLevelId })
    commit('assignStateByCateType', cateTypeData)
    // commit('dealingTwoCate', { twoCategory, cateType })
    setInitCacheData(cateTypeData)
  },

  // ssr页面初始化数据
  ssrInitCategoryData({ state, commit }, { cateType }) {
    if (!cateType) {
      // setMonitor({})
      throw new Error('ssrInitCategoryData cateType is required')
    }
    const { categoryData, categoryDataListByOrder } = formatCategoryData(
      state.dynamicCates
    )
    const { channelKey, oneCategoryKey, storageType } = cateHomeStorageData({
      cateType,
      localsLang: state.locals.lang,
    })
    const { currentActiveInfo = {} } = state
    const { channelId: channel, oneCate, channelIndex, isNew } = currentActiveInfo
    const rightCateWidth = state[cateType].rightCateWidth
    const {
      oneCategoryExpand,
      expandRearFirstLevel,
      expandFirstLevelId,
      oneCategory,
      twoCategory,
    } = getUpdateCategoryPageInit({
      categoryData,
      dynamicCates: state.dynamicCates,
      language: state.language,
      // allTabAbt: state.allTabAbt,
      locals: state.locals,
      cateExpand: state.cateExpand,
      channel, 
      oneCate,
      rightCateWidth,
      isNew
    })

    const stateCategoryData = {
      categoryData,
      categoryDataListByOrder,
      expandRearFirstLevel,
      expandFirstLevelId,
    }
    const categoryTypeData = {
      cateType,
      initCateData: true,
      tabLoadingStatus: false,
      oneCateLoadingStatus: false,
      twoCateLoadingStatus: false,
      channelKey,
      oneCategoryKey,
      storageType,
      oneCategoryExpand,
      oneCategory,
      twoCategory,
      // twoCategoryInitDone: true,
      active: {
        channel,
        oneCate,
        channelIndex,
        isNew
      },
    }
    commit('assignState', stateCategoryData)
    commit('assignStateByCateType', categoryTypeData)
    setInitCacheData(categoryTypeData)
    markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
  },

  async fetchDataByChannelId(
    { commit, state },
    { channelId, cateType = '', oneCate, needLoading = false } = {}
  ) {
    const isFirst = state.dynamicCates.length === 0
    const currentIndex = state.dynamicCates?.findIndex?.(item => Number(item.id) === Number(channelId)) || 0
    const newDynamicCates = [...state.dynamicCates || []]
    const currentItem = newDynamicCates[currentIndex] || {}
    const isChangeChannel = !currentItem.child?.length || needLoading // 整个分类频道页都没有数据

    if (!isFirst || needLoading) {
      commit('assignStateByCateType', {
        cateType,
        oneCateLoadingStatus: isChangeChannel, // 左边骨架屏
        twoCateLoadingStatus: true, // 右边骨架屏
      })
    }
    const { data = [], isNew = false } = await getCateByChannelApi({ channelId, cateType, oneCate })
    const channelChild = data?.[0]?.child || []
    const style = data?.[0]?.style || []
    const pageDown = data?.[0]?.pageDown || 0
    // return
    // 兼容新老接口切换
    if (isChangeChannel || currentItem.isNew != channelChild.isNew) {
      if (isChangeChannel || cateType === 'sidecat') {
        currentItem.child = channelChild
      } else {
        currentItem.child[oneCate] = channelChild[oneCate]
      }
      currentItem.isNew = isNew
      currentItem.pageDown = pageDown
      currentItem.style = style
    } else {
      // 当前频道一级分类更新。 需保存之前有child的状态
      currentItem.child.forEach(item => {
        if (!item.child?.length) {
          const { child = [] } = channelChild.find(cate => cate.id === item.id) || {}
          item.child = child
        }
      })
    }

    const { categoryData, categoryDataListByOrder } = formatCategoryData(newDynamicCates)

    // update dynamicCates
    setTimeout(()=>{
      setMonitor({
        oneCategory: categoryData[channelId]?.child || [], 
        twoCategory: categoryData[channelId]?.child?.[0]?.formatData || []
      })
    }, 200)
    commit('assignState', {
      dynamicCates: newDynamicCates,
      categoryData,
      categoryDataListByOrder,
    })

    if (!isFirst) {
      commit('assignStateByCateType', {
        cateType,
        oneCateLoadingStatus: false,
        twoCateLoadingStatus: false, // 右边骨架屏
        twoCategory: channelChild[oneCate]?.child?.contents ?? []
      })
    }
  },

  // 用于其他页面重新计算人群频道
  refreshCategoryCrowdInfo({ commit, dispatch }, { cateType = '' } = {}) {
    getCateCrowdInfoApi().then(({ categoryCrowdInfo }) => {
      const crowdChannel = dispatch('getCrowdChannel', { cateType })
      commit('assignState', { categoryCrowdInfo, crowdChannel })
    })
  },

  // 获取当前人群的channel
  getCrowdChannel({ state }, { cateType = '' } = {}) {
    if (!cateType) return console.log('miss catetype')
    let crowdChannelValue = ''
    const categoryCrowdInfo = state.categoryCrowdInfo
    const categoryData = state.categoryData

    if (
      categoryCrowdInfo &&
      categoryCrowdInfo.crowdAbt &&
      categoryCrowdInfo.crowdAbt.p === 'type=A' &&
      categoryCrowdInfo.crowdId
    ) {
      for (const key in categoryData) {
        if (
          Number(categoryData[key].crowdId) ===
          Number(categoryCrowdInfo.crowdId)
        ) {
          crowdChannelValue = categoryData[key].channel_type
        }
      }
    }
    return crowdChannelValue
  },

  async getInitActiveInfo(
    { state, dispatch },
    { cateType, query = {}, channelKey, oneCategoryKey, storageType, special }
  ) {
    const { channel, oneCategoryIndex } = getInitActiveInfoIndex(
      { state },
      {
        channelKey,
        oneCategoryKey,
        storageType,
        queryOneCate: query.oneCate,
        queryChannel: query.channelId,
      }
    )

    // 检测当前channel是否已经加载过数据
    await dispatch('checkChannelLoadedData', { channel, cateType, special })
    if (!state.categoryData[channel]) {
      state.categoryData[channel] = { child: [] }
    }
    let itOneCates = state.categoryData[channel].child
    let oneCate = itOneCates[oneCategoryIndex] ? Number(oneCategoryIndex) : 0

    // 如果是父级第一个title，且没有子级，那么默认选中下一个title
    const oneCateData = itOneCates[oneCate] || {}
    if (oneCateData.isFatherFirstTitle && !oneCateData.child?.length) {
      oneCate = oneCate + 1
    }

    await dispatch('checkOneCategoryLoadedData', {
      channel,
      oneCate,
      cateType,
    })

    return {
      channel,
      oneCate,
      channelIndex: state.categoryData[channel].index,
    }
  },

  // 侧边分类页更新状态
  async handleSideCategoryUpdate(
    { commit, state, dispatch },
    { cateType = '', special } = {}
  ) {
    const { channelKey, oneCategoryKey, storageType } = state[cateType]

    const { channel, oneCate, channelIndex } = await dispatch(
      'getInitActiveInfo',
      { cateType, channelKey, oneCategoryKey, storageType, special }
    )

    commit('assignStateByCateType', {
      cateType,
      active: { channel, oneCate, channelIndex },
    })

    service.cacheSelectedIndex({
      channel,
      oneCate,
      channelKey,
      oneCategoryKey,
      storageType,
    })
  },

  async checkChannelLoadedData({ dispatch, state }, { channel, cateType, special }) {
    if (!channel) console.error('[category] miss channel')
    // check has data by channel
    let data = state.categoryData?.[channel]?.child || []
    let needRequest =  false
    if (cateType === 'sidecat' ) {
      // 判断data 下的的 imgSrc 属性的值是否以http或者https开头
      needRequest = !data.every(item => item.imgSrc && item.imgSrc.startsWith('http'))
    }
    if (data.length <= 0 || special || needRequest) {
      // debugger
      await dispatch('fetchDataByChannelId', {
        channelId: channel,
        cateType,
        needLoading: needRequest,
      })
    }
  },
  async checkOneCategoryLoadedData(
    { state, dispatch },
    { channel, oneCate, cateType }
  ) {
    if (!channel) console.error('[category] miss channel')

    const { twoCategory } = getOriginTwoCategoryInfo({
      categoryData: state.categoryData,
      channel,
      oneCate,
    })
    if (twoCategory.length <= 0 && !state.isPreview) {
      await dispatch('fetchDataByChannelId', {
        channelId: channel,
        cateType,
        oneCate
      })
    }
  },

  setOneCategory({ commit, state }, { cateType }) {
    let { oneCategoryExpand, oneCategory } = getOneCategoryOrExpand({
      channel: state[cateType].active.channel,
      categoryData: state.categoryData,
    })
    commit('assignStateByCateType', {
      cateType,
      oneCategory,
      oneCategoryExpand,
    })
  },

  setTwoCategory({ commit, state }, { cateType }) {
    const { cateExpand, locals, language, dynamicCates, categoryData } = state // cateExpand: 点击后并展开才会有cateExpand
    const { rightCateWidth, active } = state[cateType] || {} // rightCateWidth:配置的常量
    const twoCategory = getTwoCategoryData(
      { locals, language },
      {
        dynamicCates,
        categoryData,
        channel: active.channel,
        oneCate: active.oneCate,
        cateExpand,
        rightCateWidth,
        isNew: categoryData[active.channel].isNew
      }
    )
    commit('assignStateByCateType', {
      cateType,
      twoCategory,
    })
  },
  ...bffActions
}

export default actions

// import schttp from 'public/src/services/schttp'

export default {
  namespaced: true,
  state: {
    beltLoaded: false,
    flashSaleCountDownTime: 0,
  },
  mutations: {
    updateBeltLoaded(state, val) {
      state.beltLoaded = val
    }
  },
  getters: {
    isPriceBannerTypeAbtNew(state, getters, rootState) {
      const { commonInfo } = rootState.newProductDetail.coldModules || {}
      const { IS_RW } = commonInfo || {}
      return !IS_RW
    },
    isFlashSaleCountDownAbtHide(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/fsAbt']?.FlashSaleCountDown?.p?.FlashSaleCountDown === 'Hide'
    },
    isBrandFlash(state, getters, rootState, rootGetters) {
      const flashType = rootGetters['newProductDetail/common/flashType']
      return flashType == 6
    },
    brandFlashLinkClickAble(state, getters, rootState, rootGetters) {
      let brandskip = rootGetters['newProductDetail/fsAbt']?.brandskip?.p?.brandskip || 'Cannotjump'
      return brandskip === 'Jumpable'
    },
    showPriceBelt(state, getters, rootState, rootGetters) {
      const isSpecialFlash = rootGetters['newProductDetail/common/isSpecialFlash']
      const flashType = rootGetters['newProductDetail/common/flashType']
      const newFlashPromotion = rootGetters['newProductDetail/common/newFlashPromotion']
      const isSsr = rootGetters['newProductDetail/isSsr']
      let { realTimeReady } = rootState['newProductDetail'].common || {}
      if (rootGetters['newProductDetail/Price/isInversion']) return false
      if (rootGetters['newProductDetail/Price/isInversionDe']) return false
      if (!isSsr && !realTimeReady ) return false
      if (getters.isBrandFlash) return true
      if (!getters.isPriceBannerTypeAbtNew) return false
      if (isSpecialFlash) return true
      if ([1, 5].includes(Number(flashType)) || ([4].includes(Number(flashType)) && newFlashPromotion)) return true
      return false
    }
  },
  // actions: {
  //   async updateServerTime({ state }) {
  //     const data = await schttp({
  //       url: '/api/common/servicesTime/get',
  //     })
  //     state.flashSaleCountDownTime = data.zzz
  //   },
  // }
}

// import { template } from '@shein/common-function'
// import { getEstimatedFinalInfo } from 'public/src/pages/goods_detail/components/top/innerComponents/Prices/components/EstimatedTag/util.js'
// import { mapRootGetters } from 'public/src/pages/goods_detail/utils/storeUtils.js'
// import salePriceWordSize from 'public/src/pages/goods_detail/utils/salePriceWordSize'

export default {
  namespaced: true,
  state: {
  },
  getters: {
    mainPriceInfo() {
      
    },
    discountPercentInfo() {

    },
    retailInfo() {

    },
    estimatedInfo() {

    },
    suggestedSalePriceInfo() {

    },
  }
}

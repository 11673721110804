const state = {
  isSSR: false,
  locals: null,
  noticeText: '',
  abtInfo: {},
  language: {},
  trackInfo: {},
  orderTrackInfo: {},
  currentPackage: [],
  shippingTime: {},
  cspNotice: [],
  saleTypeInfo: {},
  trackBanner: {},
  customsInterception: [],
  trackBannerNew: {},
  updateShippedAddress: {},
  packageCommentList: [],
  apolloInfo: {},
  isCurrentSite: false
}

export default state

// 格式化树状结构
const formatTree = (keyMap, sourceData, isDelete = true) => {
  return function fn (keyMap, sourceData) {
    sourceData.forEach(item => {
      Object.keys(keyMap).forEach(key => {
        item[keyMap[key]] = item[key]
        if (isDelete && key != keyMap[key]) {
          delete item[key]
        }
      })
      if (item.children) {
        fn(keyMap, item.children)
      }
    })
    return sourceData
  }(keyMap, sourceData)
}

const formatMuitlData = (keyMap, sourceData) => {
  const mapKeys = Object.keys(keyMap)
  const dataItemKeys = Object.keys(keyMap.dataItem)

  sourceData.forEach(item => {
    mapKeys.forEach(key => {
      if (item[key]) {
        item[keyMap[key]] = item[key] || ''
      }
      if (key != keyMap[key]) {
        delete item[key]
      }
    })

    if (Array.isArray(item?.data)) {
      item.data.forEach(subItem => {
        dataItemKeys.forEach(key => {
          if (subItem[key]) {
            subItem[keyMap.dataItem[key]] = subItem[key] || ''
          }
          if (key != keyMap.dataItem[key]) {
            delete subItem[key]
          }
        })
      })
    }
  })
  return sourceData
}

// 格式化单选
const formatSingle = (keyMap, sourceData) => {
  sourceData.forEach(item => {
    Object.keys(keyMap).forEach(key => {
      item[keyMap[key]] = item[key]
      delete item[key]
    })
  })

  return sourceData
}

// 单类数据解析
const filterSingleLink = (treeData, id) => {
  return (function filterCate (_fromTreeData) {
    if (!_fromTreeData.length) return _fromTreeData
    const isSelected = id && id == (_fromTreeData[0].value || _fromTreeData[0].cat_id)
    return _fromTreeData.length === 1 && !isSelected ? filterCate(_fromTreeData[0].children) : _fromTreeData
  })(treeData)
}

const getCateResult = ({ curValue = '', childNodeList = [] }) => {
  if (!childNodeList?.length) {
    return { filterData: [], nodeList: [] }
  }
  for (let i = 0; i < childNodeList.length; i++) {
    const childNode = childNodeList[i]

    if (String(curValue).split(',').length == 1) { // 单个分类
      
      if (childNode.value == curValue || childNode.cat_id === curValue) {
        if (childNode.children.length) {
          return { filterData: childNode.children, nodeList: [childNode] }
        }
        return { filterData: childNodeList, nodeList: [] }
      } else if (childNode.children.length) {
        const { filterData, nodeList } = getCateResult({ curValue, childNodeList: childNode.children }) || {}
        if (filterData) {
          return { filterData, nodeList: [childNode, ...nodeList] }
        }
      }
    // TODO:
    } else { // 多个分类
      return { filterData: childNodeList, nodeList: [] }
    }
  }
}

const getSubDataAndLinks = ({ treeData, curValue }) => {
  const childNodeList = filterSingleLink(treeData)
  if (curValue) {
    return getCateResult({ curValue, childNodeList })
  }
  return { filterData: childNodeList, nodeList: [] }
}

const getDropdownDataList = (treeList = [], curValue) => {

  const stack = []
  const treeArray = []

  const iterateTree = (treeList, curValue, level = 0) => {

    treeList.forEach(item => {

      item.level = level

      if (item.children.length) {
        const AllNode = {
          label: 'All',
          value: item.value,
          parent_id: item.parent_id,
          actived: false,
          level: item.level + 1,
          children: [],
        }
        item.children.unshift(AllNode)
      }

      stack.push(item)
      iterateTree(item.children, curValue, level + 1)
      const topItem = stack.pop()

      if (topItem.value === curValue) {
        item.actived = true
        stack.push(topItem)

        treeArray.unshift(treeList)
      } else {
        item.actived = false
      }
    })
  }

  iterateTree(treeList, curValue)

  return treeArray || []
}

export {
  formatTree,
  formatMuitlData,
  formatSingle,
  filterSingleLink,
  getSubDataAndLinks,
  getCateResult,
  getDropdownDataList,
}

import schttp from 'public/src/services/schttp'

export const getCategoriesApi = async (params = {}) => {
  const result = await schttp({
    url: '/api/category/list/get',
    method: 'get',
    params,
    isPreLoad: true,
  })
  return result
}
export const getCategoriesSideApi = async (params = {}) => {
  const result = await schttp({
    url: '/api/category/sideList/get',
    method: 'get',
    params,
    isPreLoad: true,
  })
  return result
}

export const getCateByChannelApi = (params = {}) => {
  return schttp({
    url: '/api/category/channel/get',
    method: 'get',
    params
  })
}

export const getCateCrowdInfoApi = (params = {}) => {
  return schttp({
    url: '/api/category/crowd/info/get',
    method: 'get',
    params
  })
}



// 获取顶部导航tab数据
export const getTopNavApi = () => {
  return schttp({
    url: '/ccc/nav/tab_cate',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    useBffApi: true
  })
}

// 获取导航左侧内容数据
export const getLeftContentApi = (params) => {
  return schttp({
    url: '/ccc/nav/left',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    useBffApi: true,
    params
  })
}

// 获取导航右侧内容数据
export const getRightContentApi = (params) => {
  return schttp({
    url: '/ccc/nav/right',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    useBffApi: true,
    params
  })
}

// 批量获取导航右侧内容数据
export const getRightContentBatchApi = (params) => {
  return schttp({
    url: '/ccc/nav/right/batch',
    method: 'POST',
    useBffApi: true,
    data: params,
  })
}

// 获取多语言数据
export const getLanguageApi = (params) => {
  return schttp({
    url: '/system/configs/multi_language',
    method: 'POST',
    data: params,
    headers: {
      'Content-Type': 'application/json'
    },
    useBffApi: true
  })
}

// 按照页面获取多语言数据
export const getLanguageByPageApi = (params) => {
  return schttp({
    url: '/bff-api/system/configs/page_multi_language_mappin',
    method: 'POST',
    data: params,
    headers: {
      'Content-Type': 'application/json'
    },
    useBffApi: true
  })
}

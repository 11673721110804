import { mapRootGetters } from 'public/src/pages/goods_detail/utils/storeUtils.js'
import { htmlDecodeCommonFn } from 'public/src/pages/common/utils/index.js'
import { template, getQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { buyNow } from 'public/src/pages/product_app/store/modules/product_detail_new/utils/buyNow.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { SILogger } from 'public/src/pages/common/monitor/index.js'

let emarsys = null
const isNewBadge = [70007003, 70007004, 70007005, 70007006] //判断角标是否为new

daEventCenter.addSubscriber({ modulecode: '2-3' })
daEventCenter.addSubscriber({ modulecode: '1-6-4' })
daEventCenter.addSubscriber({ modulecode: '1-8-1' })
/* globals limit_description,limit_count */
// 混入预搜页找相似进入的埋点参数
const assignPreSearchSimilarAnalysis = data => {
  const isFromPreSearchSimilarItems = !!getQueryString({ key: 'preSearchSimilarItems' })
  const srcTabPageId = getQueryString({ key: 'src_tab_page_id' }) || ''
  if (isFromPreSearchSimilarItems && srcTabPageId) {
    const result = Object.assign(
      data,
      {
        src_module: 'presearch_similar',
        src_identifier: '-',
        src_tab_page_id: srcTabPageId
      },
      {}
    )
    return result
  }
  return data
}
export default {
  async addToBag(
    // eslint-disable-next-line no-unused-vars
    { commit, dispatch, rootState, rootGetters },
    {
      interestpoints,
      target,
      isReview = false,
      btnType = 'add_to_bag',
      scrollToRecommend = true,
      reportPostData = {},
      reportClickData = {},
      syncAddCartColorSwitch = true,
      isFromPromotion = false,
    }
  ) {
    const goodsReady = rootState.newProductDetail?.common?.goodsReady
    if (!goodsReady) return
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const saleAttrList = rootGetters['newProductDetail/common/saleAttrList']
    const mallCode = rootGetters['newProductDetail/common/mallCode']
    const { attrSize = {}, detail = {} } = rootState.newProductDetail.coldModules?.productInfo || {}
    const defaultMallCode = rootGetters['newProductDetail/common/defaultMallCode']
    const language = rootGetters['newProductDetail/common/language']
    const { getEstimatedInfo } = mapRootGetters(rootGetters, 'common', ['getEstimatedInfo'])
    const { addToBagForm, skuInfo, quickShip } = rootState.newProductDetail?.common || {}
    var params = addToBagForm
    let variant = ''
    const hasAttrs = saleAttrList?.skcSaleAttr?.length

    params.goods_id = detail?.goods_id
    params.sku_id = detail?.goods_sn

    const saleAttrsFoldQuickPop = rootGetters['newProductDetail/common/saleAttrsFoldQuickPop']
    let sku_code = ''
    if (hasAttrs) {
      sku_code = skuInfo?.sku_code || ''
    } else {
      sku_code = saleAttrList?.skuList?.[0]?.sku_code || ''
    }
    if (saleAttrsFoldQuickPop || hasAttrs && !sku_code) {
      // 实验A  快加车弹窗
      if (isReview) {
        // 评论页
        commit('common/updateCommentQuickAddState', true)
      } else if (getEstimatedInfo?.isSatisfiedBuyMultiple && isFromPromotion) {
        commit('common/updateBuyMultipleQuickAddState', {show: true, from: 2})
      } else {
        commit('common/updateSyncAddCartColorSwitch', syncAddCartColorSwitch)
        commit('common/updateQuickAddState', true)
      }
      let extraData = {
        interestpoints,
        isLiseEmpty: true, // 不传递数据
        review_location: isReview ? 'page_review' : '',
        button_type: btnType,
        ...(reportClickData || {})
      }
      extraData = assignPreSearchSimilarAnalysis(extraData)
      // 【埋点统一】触发埋点
      daEventCenter.triggerNotice({
        daId: '2-3-5',
        target, // Dom元素
        extraData
      })
      return
    }
    var url =
          '/api/cart/add_mall/create?goods_id=' +
          params.goods_id +
          '&quantity=' +
          params.quantity +
          '&size='
    
    let buyMultipleQuantity = getEstimatedInfo?.isSatisfiedBuyMultiple && isFromPromotion ? getEstimatedInfo?.buyMultiplePic : ''
    var ajax_data = {
      // add_source: 'detailPage',
      goods_id: params.goods_id,
      quantity: buyMultipleQuantity || params.quantity,
      attrs: [],
      sku_code,
      mall_code: String(mallCode || '')
    }
    
    if (rootGetters['newProductDetail/common/isCustomization']) {
      const customerMade = await import(
        'public/src/pages/goods_detail/components/drawer/innerComponents/CustomeowDrawer/customerMade.js'
      )
      const customization_info = await customerMade?.default?.({
        sku: sku_code,
        errorTip: language?.SHEIN_KEY_PWA_26887
      })
      if (!customization_info) return
      ajax_data.customization_info = customization_info
    }
    
    if (hasAttrs) {
      skuInfo.sku_sale_attr.forEach(item => {
        if (item.attr_id == 87) {
          variant = item.attr_value_name
        }
        ajax_data.attrs.push({
          attr_id: item.attr_id,
          attr_value_id: item.attr_value_id
        })
      })
    }
    var traceid = gbExposeTraceid('getProduct', {
      goods_id: detail.goods_id
    })
    if (traceid) {
      ajax_data.trace_id = traceid
    }
    
    // 错误信息
    const errorInfo = JSON.stringify({
      url,
      goodsInfo: detail,
      attrList: attrSize,
      requestParams: ajax_data,
      saleAttrList: saleAttrList,
      skuInfo: skuInfo,
      hasAttrs
    })
    commit('common/updateAddToBagLoading', true)
    appEventCenter.$emit('allLazyComponentRender')
    const result = {
      data: null,
      error: null,
      success: false
    }
    await schttp({
      url,
      method: 'POST',
      data: ajax_data
    })
      .then(async data => {
        Object.assign(result, {
          data,
          success: true
        })
        appEventCenter.$emit('recommend-refresh-data')
        if (isFromPromotion) {
          window.vBus && window.vBus.$emit('addToBagSuccess', { data, delayToast: 0 })
        } else {
          dispatch('autoGetCouponAtAddBag', { needToast: data?.code == 0, delayToast: 0 })
        }
        if (data.code == 0) {
          const classHook = isReview ? '.j-header-bag-is-review' : '.j-header-bag'
          const $headerBag = document.querySelector(classHook)
          commit('common/updateAddCompleteStatus', true)
    
          // 从大图模式点击到手价弹出时，点击加车关闭大图
          isFromPromotion &&
                commit(
                  'PromotionEnter/updateViewPromotionAddBag',
                  Number(rootState.newProductDetail?.PromotionEnter?.viewPromotionAddBag) + 1
                )
          const initExposeStatus = rootState.newProductDetail.common?.initExposeStatus
          dispatch(
            'common/updateExposeStatus',
            {
              addBagStatus: 1,
              addWishStatus: initExposeStatus?.addWishStatus || 0
            }
          )
          commit(
            'changeCartInfo',
            {
              sum: data.info.info.cartSumQuantity || 0,
              carts: data.info.info.carts || [],
              info: data.info.info || {}
            },
            { root: true }
          )
          // 点击商详加车状态和汇总当天加车次数
          commit('BagData/updateIsAddCartInDetail')
          commit('BagData/updateAddBagCountOnTheDay')
          window.vBus && window.vBus.$emit('triggerAddCompletedAnimation')
          setTimeout(async () => {
            $headerBag.classList.add('bounce')
            if (!isReview && scrollToRecommend) {
              if (fsAbt?.addbagpopup?.param?.addbagpopup === 'show') {
                dispatch(
                  'openDetailRecommendDrawer',
                  { name: 'addCart' }
                ).then(status => {
                  if (!status) {
                    dispatch('common/setGoodsDetailScroll')
                  }
                })
              } else {
                dispatch('common/setGoodsDetailScroll')
              }
            }
    
            if (data.info.info.cartSumQuantity > 0 && $('.j-header-cart-num').length) {
              $('.j-header-cart-num')
                .removeClass('mshe-hide')
                .html(data.info.info.cartSumQuantity > 99 ? '99+' : data.info.info.cartSumQuantity)
            }
            dispatch('common/updateCartFreeInfo', {
              cartTagTipsExcludeScroll: !isReview && scrollToRecommend
            })
          }, 500)
        } else {
          if (['500304', '500305'].indexOf(data.code) >= 0) {
            commit(
              'common/updatePromotionLimitType',
              data.code == '500304' ? 1 : 2
            )
            if (data.code == '500304') {
              setTimeout(function () {
                commit('common/updatePromotionLimitType', null)
              }, 1500)
            }
          } else {
            commit('common/updateAddToBagFail', true)
            if (data.code == '300405') {
              commit(
                'common/updateAddToBagFailMsg',
                limit_description.replace('N', limit_count)
              )
            } else if (data.code == '300402') {
              const sku_list = saleAttrList.skuList
              for (let i = 0; i < sku_list.length; i++) {
                if (sku_list[i].sku_code == ajax_data.sku_code) {
                  const stockIndex =
                        sku_list[i].mall_stock?.findIndex?.(_ => _.mall_code == mallCode) || {}
                  if (stockIndex > -1) {
                    sku_list[i].mall_stock[stockIndex].stock = 0
                  }
                }
              }

              commit(
                'common/updateAddToBagFailMsg',
                language.SHEIN_KEY_PWA_15230
              )
              // TODO: 像是没用的了
              dispatch(
                'common/SizeBox/updateSizeStock',
                { skuList: sku_list }
              ) // 新数据流
            } else if (['200401', '500301'].indexOf(data.code) >= 0) {
              commit(
                'common/updateAddToBagFailMsg',
                language.SHEIN_KEY_PWA_14967
              )
            } else if (['500306', '500302', '300417'].includes(data.code)) {
              const newAddToBagForm = addToBagForm
              if (newAddToBagForm.quantity > data.info.limitCount) {
                newAddToBagForm.quantity = 1
              }
              const msgText = template(data.info?.limitCount || '', data.info?.resultLimit || 0, data.info?.remainNum || 0, data.tips)
              commit(
                'common/updateAddToBagFailMsg',
                msgText
              )
              commit('common/updateAddToBagForm', newAddToBagForm)
            } else if (data.code == 500303) {
              const msgText = template(data.info?.limitCount || '', data.info?.resultLimit || 0, data.info?.remainNum || 0, data.tips)
              commit(
                'common/updateAddToBagFailMsg',
                msgText
              )
            } else if (data.code == '300407') {
              commit('common/updateAddToBagFailMsg', data.tips)
            } else {
              if (data.code == '200301') {
                window.ErrorJs.sendError('error', `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`, {
                  errorMark: 'AddBagBrowserPwaError',
                  errorInfo
                })
              }
              commit(
                'common/updateAddToBagFailMsg',
                language.SHEIN_KEY_PWA_15282
              )
            }
            setTimeout(function () {
              commit('common/updateAddToBagFail', false)
            }, 1500)
          }
          window.vBus && window.vBus.$emit('addToBagFail', params)
        }
        if (!emarsys) {
          emarsys = require('public/src/services/productRecommend/emarsys').default
        }
        data.code == 0 && emarsys && emarsys().checkCart()
        // 【埋点统一】触发埋点
        let { isAb, isAbCoexist, isSatisfied, isSatisfiedBuyMultiple } = getEstimatedInfo || {}
        const badgeInfo = rootGetters['newProductDetail/common/badgeInfo']
        let threshold
        if (getEstimatedInfo) {
          if (isAb) {
            threshold = 3
          } else if (isSatisfiedBuyMultiple) {
            threshold = 6
          } else if (isAbCoexist) {
            threshold = isSatisfied ? 4 : 5
          } else {
            threshold = isSatisfied ? 1 : 2
          }
        }
        const trendsInfo = rootGetters['newProductDetail/common/trendsInfo']
        let extraData = {
          code: 'goodsDetail',
          result: data,
          interestpoints,
          postData: Object.assign(
            {
              sku: params.sku_id,
              spu: detail.productRelationID || params.sku_id,
              quickShip,
              price: detail.mall[mallCode].salePrice.usdAmount,
              quantity: params.quantity,
              catId: detail.cat_id,
              variant,
              discount: detail.mall[mallCode].unit_discount,
              index: 0,
              amount: detail.mall[mallCode].salePrice.amount,
              businessModel: detail.business_model,
              storeCode: detail.store_code,
              mallCode: ajax_data.mall_code,
              is_default_mall: defaultMallCode == ajax_data.mall_code ? 1 : 0,
              mallTag: detail.mall[mallCode].mall_tags,
              location: 'page',
              image_tp: 'small',
              is_customize: ajax_data?.customization_info ? 1 : 0,
              ...(reportPostData || {}),
              ...(threshold ? { threshold } : {})
            },
            ajax_data
          ),
          from: 'main',
          review_location: isReview ? 'page_review' : '',
          traceid: SaPageInfo && SaPageInfo.page_param && SaPageInfo.page_param.traceid,
          // 加车的商品是否是新品
          isNew: isNewBadge.includes(Number(badgeInfo?.id)),
          button_type: btnType,
          ...(trendsInfo ? {
            passParams: {
              trend_tag: `${trendsInfo.trend_word_id}_${trendsInfo.product_select_id}`
            }
          } : {})
        }
        // 从预搜页找相似跳转过来的情况须多加几个参数
        extraData = assignPreSearchSimilarAnalysis(extraData)
        daEventCenter.triggerNotice({
          daId: '1-8-1-1',
          target,
          extraData
        })
      })
      .catch(function (error) {
        console.log(`加车失败`)
        Object.assign(result, { error })
        window.ErrorJs &&
              window.ErrorJs.sendError('error', `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`, {
                errorMark: 'AddBagBrowserPwaFourError',
                errorInfo: JSON.stringify({
                  err: error?.message || '',
                  ...JSON.parse(errorInfo)
                })
              })
        if (error?.status == 403) {
          // 上报日志
          SILogger.logInfo({
            tag: 'GOODS_DETAIL_PWA_CLIENT_V3',
            message: 'add_to_cart_error',
            params: {
              scene: 'add_to_cart',
              msgData: error
            }
          })
        }
      })
      .finally(() => {
        commit('common/updateAddToBagLoading', false)
        if (location.hash && !isReview) {
          history.back()
        }
      })
    
    return result
  },
  async batchAddToBag({}, { data }) {
    const result = await schttp({
      url: '/api/cart/batch_add_mall/create',
      method: 'POST',
      data,
    }).catch(() => null)
    return result
  },
  async buyNow({ commit, dispatch, rootState, rootGetters }) {
    const saleAttrList = rootGetters['newProductDetail/common/saleAttrList']
    const { skuInfo } = rootState.newProductDetail?.common || {}
    let { detail } = rootState.newProductDetail.coldModules?.productInfo || {}
    const hasAttrs = saleAttrList?.skcSaleAttr?.length
    let sku_code = ''
    if (hasAttrs) {
      sku_code = skuInfo?.sku_code || ''
    } else {
      sku_code = saleAttrList?.skuList?.[0]?.sku_code || ''
    }

    const saleAttrsFoldQuickPop = rootGetters['newProductDetail/common/saleAttrsFoldQuickPop']
    if (saleAttrsFoldQuickPop || hasAttrs && !sku_code) {
      // 未选中，走buyNow加车
      commit('common/updateBuyNowQuickAddState', true)
      daEventCenter.triggerNotice({
        daId: '1-6-1-191',
        extraData: {
          goods_id: detail?.goods_id
        }
      })
    } else {
      // 选中sku，走领券，跳转
      const { addToBagForm } = rootState.newProductDetail?.common || {}
      const { getEstimatedInfo } = rootGetters['newProductDetail/common/getEstimatedInfo'] || {}
      const { salePriceAmount } = rootGetters['newProductDetail/Price/salePrice'] || { salePriceAmount: 0 }
      const currentPrice = getEstimatedInfo?.value?.amount || salePriceAmount
      commit('common/updateBuyNowBtnLoading', true)
      let quickship_tp = 0
      if (!!rootState.newProductDetail?.common?.quickShip) {
        quickship_tp = rootGetters['newProductDetail/fsAbt']?.DetailPageQuickShipShow?.p == 'DetailShowQuick' ? 1 : 0
      } else {
        quickship_tp = 3
      }
      await buyNow({
        language: rootGetters['newProductDetail/common/language'],
        httpSuccessCallBack: async () => await dispatch('autoGetCouponAtAddBag', { needToast: true, delayToast: 0 }),
        needJump: true,
        mall_code: String(rootGetters['newProductDetail/common/mallCode']),
        quantity: addToBagForm.quantity,
        sku_code,
        checkout_total: currentPrice,

        daData: {
          goods_id: detail?.goods_id,
          quickship_tp,
          sku_id: detail?.goods_sn,
          location: 'page',
          is_buynow: '1',
          nobuynow_reason: ''
        }
      })
      commit('common/updateBuyNowBtnLoading', false)
    }
  }
}

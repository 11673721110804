
import Vue from 'vue'
const mutations = {
  SET_DATA (state, payload) {
    state.context = payload
  },
  assignState (state, payload) {
    Object.assign(state, payload)
  },
  assignList(state, payload) {
    if (state.list?.channelId) {
      state.list[payload.channelId] = payload.content
    } else {
      Vue.set(state.list, payload.channelId, payload.content)
    }
  },
}

export default mutations

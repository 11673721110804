import getters from './getters'
import actions from './actions'
export default {
  namespaced: true,
  state: {
    switch_mall_code: '',
  },
  getters,
  actions,
  mutations: {
    update_switch_mall_code(state, payload) {
      state.switch_mall_code = payload
    },
  },
}

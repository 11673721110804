import {
  timeTransformer,
  parseQueryString,
  stringifyQueryString
} from '@shein/common-function'
export default {
  initSchemaSEO(state, getters, rootState, rootGetters) {
    const { mallCode } = getters
    const { pageInfo, commonInfo, productInfo } =
      rootState.newProductDetail.coldModules
    const { metaInfo } = pageInfo
    const { sizeFormatData = {} } = productInfo
    const { host_rp, currency, originalUrl } = commonInfo
    const { priceCombo = {}, saleAttrList = {} } = sizeFormatData
    const { skuList = [] } = saleAttrList
    const detail = rootGetters['newProductDetail/common/detail']
    const googleSEO = rootGetters['newProductDetail/common/googleSEO']
    const isSsr = rootGetters['newProductDetail/isSsr']
    const commentOverView =
      rootGetters['newProductDetail/commentOverView'] || {}
    const salePrice = rootGetters['newProductDetail/Price/salePrice']
    const { hitPromotion, salePriceAmount } = salePrice
    if (googleSEO) {
      const seoJson = googleSEO.find(item => item?.name === 'goodsDetailSchema')
      return JSON.parse(seoJson?.innerHTML)
    }

    const baseOffers = {
      '@type': 'Offer',
      priceCurrency: currency,
      price: salePriceAmount,
      availability:
        detail?.stock > 0 && detail?.is_on_sale != 0
          ? 'https://schema.org/InStock'
          : 'https://schema.org/OutOfStock',
      url: `${host_rp}${originalUrl}`
    }

    let schemaSEO = {
      description: metaInfo?.meta_description,
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: detail.goods_name,
      offers: baseOffers,
      ...(detail.brandInfo?.name
        ? {
          brand: {
            '@type': 'Brand',
            name: detail.brandInfo.name
          }
        }
        : {}),
      image: detail.goods_img,
      sku: detail.goods_sn
    }
    if (+commentOverView?.comment_rank_average) {
      schemaSEO.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: +commentOverView?.comment_rank_average || 0,
        reviewCount: +commentOverView?.comment_num || 0
      }
    }

    if (hitPromotion) {
      schemaSEO.priceValidUntil = timeTransformer({
        time: hitPromotion.endTimestamp * 1000,
        defaultCode: 'A-5'
      })
    }

    if (priceCombo?.priceDetect && skuList?.length && !isSsr ) {
      return skuList?.map?.(sku => {
        const item = sku?.mall?.[mallCode]
        const queryObj = {
          ...parseQueryString(location.search),
          skucode: sku?.sku_code
        }
        const url = `${host_rp}${location.pathname}?${stringifyQueryString({
          queryObj
        })}`
        const product = {
          ...schemaSEO,
          sku: sku?.sku_code,
          offers: {
            ...baseOffers,
            price: item?.salePrice?.amount,
            url,
            availability:
            item?.stock > 0
              ? 'https://schema.org/InStock'
              : 'https://schema.org/OutOfStock'
          }
        }
        return product
      })
    }

    return schemaSEO
  },
  complianceMode(state, getters, rootState) { // 是否开启价格合规 / 后续新增合规规则 complianceModeV1 / complianceModeV2
    return rootState.newProductDetail.coldModules.pageInfo.complianceMode
  },
  complianceTipsMode(state, getters, rootState)  { // 合规文案模式
    return rootState.newProductDetail.coldModules.pageInfo.complianceTipsMode
  },
  complianceModeDe(state, getters, rootState)  { // 德国合规
    return rootState.newProductDetail.coldModules.pageInfo.complianceModeDe
  },
  isHitComplianceMode(state, getters, rootState) { // 是否命中合规模式，包含所有
    return rootState.newProductDetail.coldModules.pageInfo.isHitComplianceMode
  },
}

import { transformImg } from '@shein/common-function'
import LOCAL_STYLE_MAP from '../../utils/localStoreStyleMap'
import { blurStoreItemsNum } from 'public/src/pages/product_app/store/modules/product_detail_new/utils/blurStoreItemsNum.js'

export const isNotEmpty = (obj) => {
  return obj && JSON.stringify(obj) !== '{}'
}

export default {
  /** 是否展示商店 */
  showStore(state, getters, rootState) {
    const { store } = rootState.newProductDetail.coldModules
    const { storeInfo = {} } = store
    const storeStatus = storeInfo.storeStatus
    const storeType = storeInfo.storeType
    // 1: 平台店铺, 2: 自营店铺
    const showStore = !!([1, 2, 6].includes(+storeType) && storeStatus)
    return showStore
  },
  // 是否是品牌集成店
  isStoreBusinessBrand(state, getters, rootState) {
    return rootState.newProductDetail.coldModules?.store?.storeInfo?.storeBusinessType == 2
  },
  /**
   * 针对固定标签的商品，展示该商品的品牌信息 wiki:pageId=1200449474
   */
  isBrandHasTargetTag(state, getters, rootState) {
    /**
     * 本逻辑跟原来有单独品牌模块的逻辑不冲突，相当于是该标签商品就有单独的品牌模块，原来品牌集成店的商品走了某abt分支也有单独的品牌模块，互不相干
     */
    const TAG_ID = '600012606'
    return rootState.newProductDetail.coldModules?.tsp?.tspLabels?.tag?.includes?.(TAG_ID)
  },
  /**
   * 基于abt显示新品牌模块
   */
  showAuthenticbrand(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    return fsAbt?.Authenticbrand?.p?.Authenticbrand === 'show'
  },
  // 判断是否展示独立品牌-老样式（位于description模块上方）
  showStoreAndBrand(state, getters, rootState, rootGetters) {
    const { showStore, brandMapInfo } = getters
    const { commonInfo } = rootState.newProductDetail.coldModules
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { IS_RW } = commonInfo || {}
    // 普通店铺，并且BrandCollectionAdjust命中new需要展示
    let isStore = fsAbt?.BrandCollectionAdjust?.p?.BrandCollectionAdjust === 'new' &&
    showStore && !getters.isStoreBusinessBrand
    // 品牌集成店，并且Authenticbrand命中none才展示
    let isStoreBrand = getters.isStoreBusinessBrand && !getters.showAuthenticbrand
    return !IS_RW && brandMapInfo && (isStore || isStoreBrand)
     
  },
  // 品牌数据(数据结构转换)
  brandMapInfo(state, getters, rootState) {
    const { isStoreBusinessBrand } = getters
    const { brandAndSeries, pageInfo } = rootState.newProductDetail.coldModules
    const { language } = pageInfo || {}
    const { brandDetailInfo } = brandAndSeries || {}
    const { brand_logo, brand_code, name, brand_introduction, brand_select_info } =
      brandDetailInfo || {}
    const { sc_url: brand_sc_url, sc_url_id: brand_sc_url_id } = brand_select_info || {}
    if (brand_logo || (isStoreBusinessBrand && name)) {
      const getSeriesAndBrand = brandAndSeries?.getSeriesAndBrand || {}
      return {
        id: brand_code,
        uid: getSeriesAndBrand?._brand?.id || '',
        type: 'brand',
        logo: brand_logo ? transformImg({ img: brand_logo }) : '',
        originLogo: brand_logo,
        name,
        desc: brand_introduction,
        desc_bak: language.SHEIN_KEY_PWA_17426,
        link: brand_sc_url,
        scId: brand_sc_url_id,
        logoTips: isStoreBusinessBrand
      }
    }
    return null
  },
  // 系列数据(数据结构转换)
  seriesMapInfo(state, getters, rootState, rootGetters) {
    const { commonInfo } = rootState.newProductDetail.coldModules
    const { IS_RW, IMG_LINK } = commonInfo || {}
    const seriesDetailInfo =
      rootState.newProductDetail.coldModules.brandAndSeries?.seriesDetailInfo || {}
    const {
      id,
      series_name,
      image_url,
      series_description,
      designer_url,
      designer_id,
      series_group_name
    } = seriesDetailInfo
    const { sc_url: series_sc_url, sc_url_id: series_sc_url_id } =
      seriesDetailInfo?.relate_select?.[0] || {}
    // TODO 旧逻辑 暂时 abt 控制
    const link = designer_url ? designer_url : series_sc_url
    let newSeriesDetail = null
    // shein不判断link, rw维持原逻辑判断link
    if (image_url && series_name && series_description && (!IS_RW || link)) {
      const { brandAndSeries, pageInfo } = rootState.newProductDetail.coldModules
      const getSeriesAndBrand = brandAndSeries?.getSeriesAndBrand || {}
      const { language } = pageInfo || {}
      // 系列
      if (IS_RW && series_name && series_sc_url && series_group_name === 'ROMWE X 艺术家') {
        newSeriesDetail = {
          id,
          uid: getSeriesAndBrand?.series?.id || '',
          type: 'series',
          logo: IMG_LINK.romwex,
          name: series_name,
          desc: language.SHEIN_KEY_PWA_19574,
          link: series_sc_url,
          scId: series_sc_url_id
        }
      } else {
        // 系列接口有返回次级系列组ID，且是否按次级推荐=是，则按系列推荐
        const isSeriesRecommend =
          getSeriesAndBrand?.series?.series_group_id &&
          getSeriesAndBrand?.series?.product_recommend_by_group
        // abt实验为new时，可推荐次级系列，为old时仅推荐末级系列商品
        const fsAbt = rootGetters['newProductDetail/fsAbt']
        const CollectionGoodsRecIsNew =
          fsAbt.CollectionGoodsRec?.param?.CollectionGoodsRec === 'new'
        const designerId = !IS_RW && designer_url && designer_id
        newSeriesDetail = {
          id,
          uid: IS_RW ? getSeriesAndBrand?.series?.id : id || '',
          series_group_id:
            !IS_RW && isSeriesRecommend && CollectionGoodsRecIsNew
              ? getSeriesAndBrand?.series?.series_group_id
              : '',
          type: 'series',
          title: language.SHEIN_KEY_PC_20343,
          logo: image_url ? transformImg({ img: image_url }) : '',
          name: series_name,
          desc: series_description,
          desc_bak: language.SHEIN_KEY_PC_17600,
          scId: series_sc_url_id,
          designerId,
          link,
          isSeriesRecommend
        }
      }
    }
    return newSeriesDetail
  },
  // 品牌系列数据（合并为一 有优先级）
  brandSeriesDetail(state, getters, rootState, rootGetters) {
    const showStore = getters.showStore
    const pageCommonInfo = rootGetters['newProductDetail/common/pageCommonInfo']
    const isRWSeriesRecommend = pageCommonInfo.IS_RW || !getters.seriesMapInfo?.isSeriesRecommend
    const storeBrandSeriesRecommendGroup = rootState.newProductDetail.storeBrandSeriesRecommendGroup
    let brandSeriesDetail = getters.brandMapInfo || getters.seriesMapInfo
    if (showStore && isRWSeriesRecommend) {
      // 有店铺信息时显示相关品牌与系列
      return brandSeriesDetail
    }
    // if ((showStore && isRWSeriesRecommend) || !storeRecommendInfo?.listType) {
    //   // 有店铺信息时显示相关品牌与系列
    //   // 或没有推荐数据时显示相关品牌与系列
    //   brandSeriesDetail = getters.brandMapInfo || getters.seriesMapInfo
    // }
    if (storeBrandSeriesRecommendGroup?.brandSeries) {
      return storeBrandSeriesRecommendGroup.brandSeries.listType === 'brand' ? getters.brandMapInfo : getters.seriesMapInfo
    }
    return brandSeriesDetail
  },
  signBoardStyle(state, getters, rootState, rootGetters) {
    const { commonInfo } = rootState.newProductDetail.coldModules
    const { IS_RW } = commonInfo || {}
    let StoregoodspictureAbt = ''
    if (IS_RW) {
      const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}
      StoregoodspictureAbt = fsAbt.Storegoodspicture?.param
    } else {
      StoregoodspictureAbt = 'row=1,position=1,brand_self=true,brand_other=true,series=true,store=true'
    }
    const style = LOCAL_STYLE_MAP[StoregoodspictureAbt]
    return {
      position: !['A2', 'B1'].includes(style) ? 'p1' : 'p2', // p1 位置不动 p2 评论下方
      style
    }
  },
  // // 展示优先级 品牌集成店 > 品牌系列 > 店铺
  // detailSignBoardData(state, getters, rootState, rootGetters) {
  //   const brandSeriesDetail = rootGetters['newProductDetail/common/brandSeriesDetail'] || {}
  //   const signBoardStyle = rootGetters['newProductDetail/common/signBoardStyle'] || {}
  //   const { brandAndSeries } = rootState.newProductDetail.coldModules
  //   const pageCommonInfo = rootGetters['newProductDetail/common/pageCommonInfo']
  //   const localStoreInfo = rootGetters['newProductDetail/common/localStoreInfo']
  //   const { getSeriesAndBrand = {} } = brandAndSeries || {}
  //   const showBrandSeries = isNotEmpty(brandSeriesDetail) && isNotEmpty(getSeriesAndBrand) && (!pageCommonInfo.IS_RW && getSeriesAndBrand?.brand?.brand_code || getSeriesAndBrand?.series?.id || getSeriesAndBrand?.series_list?.length)
  //   const { storeBusinessType } = localStoreInfo
  //   let result = {}
  //   // 从新链路统一获取状态
  //   const showStoreAndBrand = rootGetters['newProductDetail/common/showStoreAndBrand']

  //   if (showStoreAndBrand || storeBusinessType == 2) {
  //     result = {
  //       // 数据结构统一
  //       showStoreAndBrand,
  //       storeData: localStoreInfo, 
  //       brandData: { ...brandSeriesDetail, logoTips: storeBusinessType == 2 },
  //       showStore: true
  //     }
  //   } else { 
  //     result = showBrandSeries
  //       ? { brandData: brandSeriesDetail }
  //       : { storeData: localStoreInfo, showStore: true }
  //   }
  //   return Object.assign(result, signBoardStyle)
  // },
  localStoreInfo(state, getters, rootState) {
    const { store } = rootState.newProductDetail.coldModules
    const { localStoreInfo } = store || {}
    if (localStoreInfo?.totals) {
      localStoreInfo.totals = blurStoreItemsNum(localStoreInfo.totals)
    }
    // 门店和品牌系列描述字段保持统一
    return Object.keys(localStoreInfo || {}).length > 0 ? { desc: localStoreInfo?.descriptions, ...localStoreInfo } : localStoreInfo
  },
  storeFollowInfo(state, getters, rootState) {
    const { store } = rootState.newProductDetail.coldModules || {}
    const { quantity } = store || {}
    const { storeFollowInfo } = rootState.newProductDetail.common || {}
    
    if (!storeFollowInfo?.show || !getters.localStoreInfo?.storeCode ) return {}
    let salesVolume = quantity
    if (salesVolume) {
      salesVolume = blurStoreItemsNum(salesVolume)
    }
    return {
      ...storeFollowInfo,
      salesVolume,
    }
  },
  isThirdPartyStore(state, getters, rootState) {
    const { store } = rootState.newProductDetail.coldModules
    const { storeInfo = {} } = store
    const storeType = storeInfo.storeType
    return storeType == 1
  },
  storeRecommendInfo(state, getters, rootState, rootGetters) {
    const signData = rootGetters['newProductDetail/Middle/signData']
    const storeBrandSeriesRecommendGroup = rootState.newProductDetail.storeBrandSeriesRecommendGroup
    if (!storeBrandSeriesRecommendGroup.loaded) return {}

    const isStore = signData?.type === 'store'
    return (isStore ? storeBrandSeriesRecommendGroup.store : storeBrandSeriesRecommendGroup.brandSeries) || {}
  },
  boardList(state, getters, rootState, rootGetters) {
    let boardList = getters.storeRecommendInfo?.boardList ?? []
    // 人工置顶
    const cccStoreGoodsRecIntervention = rootGetters['newProductDetail/Signboard/cccStoreGoodsRecIntervention']?.slice(0, 3)
    // 找到非闪购商品的第一个位置（闪购商品，通常都是排在最前面的）
    let index = boardList.findIndex(item => item.is_flash_sale != 1)
    index = index > -1 ? index : 0
    // 有闪购商品时，人工置顶插入在闪购商品后面
    cccStoreGoodsRecIntervention?.length && boardList.splice(index, 0, ...cccStoreGoodsRecIntervention)

    // 同时存在时按这个闪购-人工配置-推荐顺序排序，有重复商品时，保留前者
    let newBoardList = []
    let ids = new Set()

    for (const item of boardList) {
      if (!ids.has(item.goods_id)) {
        ids.add(item.goods_id)
        newBoardList.push(item)
      }
    }
    return newBoardList
  },
  hasBrandSeriesDetailData(state, getters, rootState, rootGetters) {
    const brandSeriesDetail = rootGetters['newProductDetail/common/brandSeriesDetail'] || {}
    return brandSeriesDetail && Object.keys(brandSeriesDetail).length > 0
  },
}

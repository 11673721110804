export default {
  language_v2(state, getters, rootState, rootGetters) {
    return rootGetters['newProductDetail/common/language']
  },
  cache_screen_data(state, getters, rootState, rootGetters) {
    // TODO: 旧数据流，预取数据
    const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
    return cacheScreenData
  },
  goods_id(state, getters, rootState) {
    return rootState.productDetail.coldModules.productInfo.goods_id || ''
  },
  mall_code(state, getters, rootState, rootGetters) {
    if (getters.cache_screen_data) {
      return getters.cache_screen_data.mallCode || ''
    }
    return state.switch_mall_code || rootGetters['newProductDetail/common/mallCode']
  }
}

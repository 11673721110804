function formatList (list = []) {
  list.forEach((item, index) => {
    item.firstImg = item.comment_image?.[0]?.member_image_middle || item.img || ''
    // 红人图没有comment_id,为了统一适配大图，添加comment_id
    !item.comment_id && (item.comment_id = item.id + '_' + index)
  })
  return list
}
export default {
  namespaced: true,
  state: {
    commentScrollLoading: false, 
    noMoreComment: false
  },
  getters: {
    commentsWithImg(state, getters, rootState) {
      const { comment } = rootState.newProductDetail.hotModules
      const { commentsWithImg = {} } = comment || {}
      return commentsWithImg
    },
    customerShowList(state, getters, rootState) {
      const { recommend, comment } = rootState.newProductDetail.coldModules
      const { freeTrailReports = [] } = comment || {}
      const commentImgList = getters.commentsWithImg?.info?.commentInfo ?? []
      const { lookBookInfo = [] } = recommend || {}
      lookBookInfo.forEach(item => item.type = 'gallery')
      const list = formatList([...lookBookInfo, ...freeTrailReports, ...commentImgList])
      return list
    },
    // 所有买家秀总数之和 红人图 + 有图评论 + 报告数（不包括站外评论）
    allCustomerGallerySum(state, getters, rootState) {
      const { recommend, comment } = rootState.newProductDetail.coldModules
      const { freeTrailReports = [] } = comment || {}
      const { lookBookInfo = []  } = recommend || {}
      const commentInfoTotal = getters.commentsWithImg?.info?.commentInfoTotal ?? 0
      const totalComment = lookBookInfo.length + freeTrailReports.length + commentInfoTotal
      return totalComment
    },
  },
  mutations: {
    updateCustomerShowList(state, payload) {
      state.customerShowList = payload
    },
  }
}

export default {
  MAIN_BFF_APOLLO: {
    v1: false, // 0716
    v2: false, // 0730
    v3: false,
  },

  // 静态模型 CDN接口
  // 注: 精准更新
  // 接口文档: https://soapi.sheincorp.cn/application/1406/versions/test01/get_product_get_goods_detail_static_data/doc
  coldModules: {
    productInfo: {
      beauty_ingredients: '',
      goods_id: '',
      selectedMallCode: '',
    },
    commonInfo: {
    },
  },

  // 动态模型
  // 注: 精准更新
  // 接口文档: https://soapi.sheincorp.cn/application/1406/versions/test01/get_product_get_goods_detail_realtime_data/doc
  hotModules: {
    storeInfo: {},
  },
}

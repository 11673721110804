import TMG from '@shein/time-management-guru'
import qs from 'query-string'

import schttp from 'public/src/services/schttp'
import other from './other'
import popup from './popup'
import addToBag from './addToBag'
import recommend from './recommend'

import {
  detailParams,
  reg
} from 'public/src/pre_requests/modules/goods_detail.js'
import switchColorCache from 'public/src/pages/goods_detail/utils/switchColorCache'
import { markPoint } from 'public/src/services/mark/index.js'

const { OPEN_DETAIL_BOTH_QUERY } = gbCommonInfo || {}

// 获取商品主数据
const getGoodsDetailMasterData = async (isSwitchColor, params) => {
  let result
  if (isSwitchColor) {
    result = switchColorCache.get(params.goods_id, 'cold')
    if (result) return result
  }
  result = await TMG.useQuickRequest(
    `pageGoodsDetail/getGoodsDetail/${params.goods_id}`,
    params,
    { onlyFirstUseQuick: true }
  )
  return result
}

const getGoodsDetailRealTimeData = async (isSwitchColor, params) => {
  let result
  if (isSwitchColor) {
    result = switchColorCache.get(params.goods_id, 'hot')
    if (result) return result
  }
  const data = await TMG.useQuickRequest(
    `pageGoodsDetail/getGoodsDetailRealTime/${params.goods_id}`,
    params,
    { onlyFirstUseQuick: true }
  )
  result = data?.data
  return result
}

const getGoodsDetailLanguageData = async () => {
  let result = await TMG.useQuickRequest(
    'pageGoodsDetailLanguage/getGoodsDetailLanguage'
  )
  return result
}

const reportMasterDataError = (error, params, beforeXhrExtra) => {
  const paramsStr = qs.stringify(params)
  const url = `/api/productInfo/productDetail/get?${paramsStr}`
  const { beforeDownlink, beforeOnline, beforeTime } = beforeXhrExtra
  const afterTime = Date.now()
  window.ErrorJs &&
    window.ErrorJs.sendError(
      'error',
      `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`,
      {
        errorMark: 'ProductXhrBrowserPwaError',
        errorInfo: JSON.stringify({
          status: error?.status || '',
          status2: error?.status,
          responseText: error?.responseText || '',
          responseURL: error?.responseURL || '',
          readyState: error?.readyState,
          responseXML: error?.responseXML,
          message: error?.message || '',
          url,
          params,
          errType: Object.prototype.toString.call(error),
          textStatus: error?.textStatus || '',
          errorThrown: error?.errorThrown || '',
          beforeDownlink,
          afterDownlink: navigator?.connection?.downlink || 999,
          beforeOnline,
          afterOnline: navigator?.onLine || 999,
          beforeTime,
          afterTime,
          disTime: afterTime - beforeTime
        })
      }
    )
}

export default {
  /**
   * @name fetchFSInfo 获取商品详情 首屏数据
   * @param {*} param0
   * @param {*} payload
   */
  async fetchFSInfo(
    { commit, rootState, rootGetters },
    { goods_id, isSwitchColor, callback, mallCode, realTimeConfig, err404 }
  ) {
    return new Promise(resolve => {
      // ssr报错排查
      if (typeof window === 'undefined') {
        throw new Error(
          JSON.stringify({
            err: 'product_detail_ssr_build_inconsistent',
            goods_id,
            detail: rootGetters['newProductDetail/common/detail']
          })
        )
      }
      const paths = location.pathname.match(reg)
      const language = rootGetters['newProductDetail/common/language']

      if (paths instanceof Array && paths[1]) {
        const params = detailParams({
          goods_id: paths[1],
          withI18n: Object.keys(language).length ? 0 : 1,
          expData: qs.parse(location.search)
        })
        const beforeXhrExtra = {
          beforeDownlink: navigator?.connection?.downlink || 999,
          beforeOnline: navigator?.onLine || 999,
          beforeTime: Date.now()
        }

        // 商品主数据 所有状态都以此接口为准
        markPoint({ eventName: 'waitPageData', measureFrom: 'routeChangeEnd' })
        if (!isSwitchColor) {
          getGoodsDetailLanguageData().then(result => {
            commit('updatePageInfoLanguageField', result)
          })
        }
        if (OPEN_DETAIL_BOTH_QUERY) {
          Promise.all([
            getGoodsDetailMasterData(isSwitchColor, params),
            getGoodsDetailRealTimeData(isSwitchColor, {
              goods_id: paths[1],
              mallCode,
              realTimeConfig
            })
          ])
            .then(([result, hotModules]) => {
              markPoint({ eventName: 'pageDataReady', measureFrom: 'waitPageData' })
              // 请求回来时可能不在详情页了
              if (
                typeof window !== 'undefined' &&
                window._gb_app_ &&
                window._gb_app_.$route.name != 'page_goods_detail'
              ) {
                return resolve('stop')
              }

              // 商品不存在特殊处理
              if (result?.code === 200301 && err404 instanceof Function) {
                err404()
                return
              }

              commit('initCold', result)
              commit('newProductDetail/SizeBox/updateRealDataMap', null, {
                root: true
              })
              commit('newProductDetail/SizeBox/updateRealSkuAllInfo', null, {
                root: true
              })
              commit('common/updateDetailPageOnloadStatus', true)
              commit('common/updateDetailFetchMoreInfoStatus', true)
              commit('common/updateGoodsReady', true)
              commit('common/updateFirstPageDataReady', true)
              markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
              Promise.resolve().then(() => {
                commit('updateHot', hotModules || {})
                callback && callback()
                const asyncScrollStatus =
                  rootState.newProductDetail.common?.asyncScrollStatus
                !asyncScrollStatus &&
                  commit(
                    'changeRootSLoadingStatus',
                    { show: false },
                    { root: true }
                  )
                switchColorCache.set(goods_id, 'cold', result)
                Vue.nextTick(() => {
                  markPoint({ eventName: 'setPageDataNextTick', measureFrom: 'setPageData' })
                })
              })
              return resolve()
            })
            .catch(error => {
              reportMasterDataError(error, params, beforeXhrExtra)
            })
        } else {
          getGoodsDetailMasterData(isSwitchColor, params)
            .then(result => {
              markPoint({ eventName: 'pageDataReady', measureFrom: 'waitPageData' })
              // 请求回来时可能不在详情页了
              if (
                typeof window !== 'undefined' &&
                window._gb_app_ &&
                window._gb_app_.$route.name != 'page_goods_detail'
              ) {
                return resolve('stop')
              }

              // 商品不存在特殊处理
              if (result?.code === 200301 && err404 instanceof Function) {
                err404()
                return
              }

              // 新数据链路
              commit('initCold', result)
              commit('newProductDetail/SizeBox/updateRealDataMap', null, {
                root: true
              })
              commit('newProductDetail/SizeBox/updateRealSkuAllInfo', null, {
                root: true
              })
              commit('common/updateDetailPageOnloadStatus', true)
              commit('common/updateDetailFetchMoreInfoStatus', true)
              commit('common/updateGoodsReady', true)
              commit('common/updateFirstPageDataReady', true)
              markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
              Promise.resolve().then(() => {

                // 获取实时数据
                getGoodsDetailRealTimeData(isSwitchColor, {
                  goods_id: paths[1],
                  mallCode,
                  realTimeConfig
                }).then(hotModules => {
                  commit('updateHot', hotModules || {})
                })

                callback && callback()
                const asyncScrollStatus =
                  rootState.newProductDetail.common?.asyncScrollStatus
                !asyncScrollStatus &&
                  commit(
                    'changeRootSLoadingStatus',
                    { show: false },
                    { root: true }
                  )
                switchColorCache.set(goods_id, 'cold', result)
                Vue.nextTick(() => {
                  markPoint({ eventName: 'setPageDataNextTick', measureFrom: 'setPageData' })
                })
              })
              return resolve()
            })
            .catch(error => {
              reportMasterDataError(error, params, beforeXhrExtra)
            })
        }
      }
    })
  },
  /**
   * @name asyncHotModles 同步动态模型 主链路动态模型
   * @param {*} param0
   * @param {*} payload
   */
  async asyncHotModles({ commit, rootGetters, rootState }, payload) {
    const { goods_id, mallCode, noUpdateRecommend } = payload
    const MAIN_BFF_APOLLO = rootState.productDetail.MAIN_BFF_APOLLO
    const [res, BFF_INFO] = await Promise.all([
      schttp({
        method: 'POST',
        url: '/api/productInfo/realTimeData/query',
        data: {
          goods_id,
          mallCode,
          realTimeConfig: {
            specialSceneType: !!rootGetters['newProductDetail/closeEstimatedAndAbPrice'] ? 1 : 0,
            estimatedPriceInfo: !rootState.newProductDetail.common?.fromSwitchColor
          }
        }
      }),
      MAIN_BFF_APOLLO?.v1 ? schttp({
        method: 'GET',
        url: '/product/get_goods_detail_realtime_data',
        params: {
          goods_id,
          mallCode: mallCode,
        },
        useBffApi: true,
      }) : null
    ])
    if (res && res.code === 0) {
      if (typeof res.data === 'object') {
        res.data.noUpdateRecommend = noUpdateRecommend
      }
      if (res.data && +BFF_INFO?.code === 0) {
        res.data.BFF_INFO = BFF_INFO?.info
      }
      commit('updateHot', res.data)
      if (typeof res.data === 'object') {
        delete res.data.noUpdateRecommend
      }
      return res.data
    }
  },
  getApolloConfig({}, key) {
    return new Promise(resolve => {
      schttp({
        url: '/api/config/apolloConfig/query',
        method: 'POST',
        data: { apolloKeys: key }
      }).then(res => {
        resolve(res?.info || {})
      })
    })
  },
  ...other,
  ...popup, // 弹窗相关
  ...addToBag, // 加车
  ...recommend, // 推荐相关
}

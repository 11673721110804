class Cache {
  constructor() {
    this.cacheGoodsId = new Set()
  }

  getCacheKey(goodsId, type) {
    return `goods_detail_${type}_cache_${goodsId}`
  }

  valdateType(type) {
    return ['cold', 'hot'].includes(type)
  }

  /**
   * @name set
   * @param {*} id goodsId
   * @param {*} type  cold | hot
   * @param {*} value
   * @returns
   */
  set(id, type = 'cold', value) {
    if (!this.valdateType(type)) {
      return
    }
    const cacheId = this.getCacheKey(id, type)
    if (this.cacheGoodsId.has(cacheId)) {
      return
    }
    this.cacheGoodsId.add(cacheId)
    Promise.resolve().then(() => {
      sessionStorage.setItem(cacheId, JSON.stringify(value))
    })
  }

  /**
   *
   * @param {*} id goodsId
   * @param {*} type cold | hot
   * @returns
   */
  get(id, type = 'cold') {
    if (!this.valdateType(type)) {
      return
    }
    const cacheId = this.getCacheKey(id, type)
    if (!this.cacheGoodsId.has(cacheId)) {
      return null
    }
    const value = sessionStorage.getItem(cacheId)
    return JSON.parse(value)
  }

  clearAll() {
    this.cacheGoodsId.forEach((key) => {
      sessionStorage.removeItem(key)
    })
    this.cacheGoodsId.clear()
  }
}

const goodsDetailCache = new Cache()
export default goodsDetailCache

import { isSwitchBffDetail } from 'public/src/pages/common/bff-new/index.js'
import pageContextConstant from 'public/src/pages/goods_detail/utils/pageContextConstant.js'
import { 
  PRODUCT_INFO_INIT,
  PRODUCT_INFO_ASYNC,
} from './constants.js'

const mutations = {
  init_cold(state, payload) {
    const { 
      productInfo = {},
    } = payload || {}
    PRODUCT_INFO_INIT.forEach(key => {
      state.coldModules.productInfo[key] = productInfo?.[key] || ''
    })
    this.commit('productDetail/update_main_bff_apollo')

    this.commit('productDetail/init_async_cold', payload)
    // const timer = setTimeout(() => {
    //   clearTimeout(timer)
    //   this.commit('productDetail/init_async_cold', payload)
    // })
  },
  init_async_cold(state, payload) {
    const { productInfo = {} } = payload || {}
    PRODUCT_INFO_ASYNC.forEach(key => {
      state.coldModules.productInfo[key] = productInfo?.[key] || ''
    })
  },
  update_main_bff_apollo(state) {
    const MAIN_BFF_APOLLO = state.MAIN_BFF_APOLLO || {}
    const mainBffApolloKeys = Object.keys(MAIN_BFF_APOLLO) || []
    const mainBffApolloValues = isSwitchBffDetail(mainBffApolloKeys)
    const { WEB_CLIENT } = gbCommonInfo || {}
    let isSsrRender = pageContextConstant.get('isSsrRender')
    mainBffApolloKeys.forEach(key => {
      state.MAIN_BFF_APOLLO[key] = !!(!isSsrRender && WEB_CLIENT === 'shein' && mainBffApolloValues[key])
    })
  },
  update_hot(state, payload) {
    const { storeInfo } = payload || {}
    state.hotModules.storeInfo = storeInfo || {}
  },
}

export default mutations

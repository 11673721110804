import { recommendPublicProductItemConfig, extendsComplianceModeConfig } from 'public/src/pages/goods_detail/RecommendRelatives/constants.js'
import { getFormatBuyboxProductList } from 'public/src/pages/common/BuyBoxPopup/utils.js'

export default {
  lookbookInfo(state, getters, rootState) {
    return rootState.newProductDetail.coldModules.recommend.lookBookInfo || []
  },
  // 额外商品项配置，所有推荐列表共享
  unifiedRecommendPrdConfig(state, getters, rootState, rootGetters) {
    const unifiedRecommend = rootGetters['newProductDetail/unifiedRecommend']
    const { publicPrdItemConfig } = unifiedRecommend || {}

    return {
      ...(publicPrdItemConfig || {}),
    }
  },
  // 额外商品项（一行两图）配置，所有推荐列表共享
  twoGoodsRecPrdConfig(state, getters, rootState, rootGetters) {
    const unifiedRecommend = rootGetters['newProductDetail/unifiedRecommend']
    const { twoGoodsRecItemsConfig } = unifiedRecommend || {}
    return {
      ...(twoGoodsRecItemsConfig || {}),
    }
  },
  // 新商卡 v3 额外配置
  newGoodsCardV3Config(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const unifiedRecommend = rootGetters['newProductDetail/unifiedRecommend']
    const { newGoodsCardBaseConfig } = unifiedRecommend
    const { showTitle, showTitleTwoLine, showAddBagBtn } = newGoodsCardBaseConfig || {}
    const {
      newPrice,
      carddiscountLabel,
      drankinglabelclick,
      DetailStarReview,
      detailKeyAttribute,
      listtagSorting,
      listrankingTag,
      listattributeLabels
    } = fsAbt
    // 折扣标签样式控制
    const showDiscountLabel = carddiscountLabel?.p?.carddiscount !== 'off'
    const discountLabelLocation = carddiscountLabel?.p?.labelLocation
    const showDiscountLabelBeforeGoodsNameLowPriority = discountLabelLocation === 'titleLow'
    const showDiscountLabelBeforeGoodsNameHighPriority = discountLabelLocation === 'titleHigh'
    const showHorseRaceLabel = !!listtagSorting?.param?.tagsortingContent
    const addRankingLabelToSwiper = listrankingTag?.param?.rankingTag === 'on'
    /**
       * 新商卡 v3 配置
       * itemconfig ｜ 名称 ｜ field 
       */
    // showMultiColor 色块 field=locateLabels
    // showBeltLabel 腰带 field = beltLabel 
    // showRankingList 榜单 fields=rankingList 
    // rankingLabelClickable 榜单是否可以点击
    // showStarComment 卖点信息-星级评论数量 filed=starComment 
    // 无 卖点-通用行为标签信息 field = userBehaviorLabel
    // 无 卖点-用户评论关键词 field= userCommentKeywordsLabel 
    // showDecisionAttr 卖点-决策属性 filed = enableDecisionAttr 
    // showSellingPoint 卖点-人工卖点标签 filed=sellingPoints
    // 无 原价/折后价   无 闪购价/限时闪购价    prices
    // 无 （showSheinClubPrice）付费会员标签/付费会员价  filed= sheinClubPromotionInfo
    // 无 免费会员  field=vipDiscountPrices
    // 无 新用户价  field = newFlashPromotions
    // showEstimatedPriceReachThreshold 到手价 fileds= estimatedPrice
    // showQuickShip 服务类标签-【quick ship】  filed = quickShip 
    // showLocalSeller 服务类标签-【local shipping】 调用预处理服务传入一个 mallInfoForSite（接口获取）
    // 无 服务类标签-【buy more】 filed=promotion
    // showBadge 服务类标签-【通用角标类型-优选卖家】 field = serviceLabel
    // 无  new 角标 labelsBeforeGoodsName
    const itemConfigByAbt = {
      showDiscountLabelBeforeGoodsName: showDiscountLabel && showDiscountLabelBeforeGoodsNameLowPriority, // 折扣标签优先级低于标题前的其他标签
      showDiscountLabelBeforeGoodsNameHighPriority: showDiscountLabel && showDiscountLabelBeforeGoodsNameHighPriority, // 折扣标签优先级高于标题前的其他标签
      showDiscountLabelAfterPrice: showDiscountLabel && !showDiscountLabelBeforeGoodsNameLowPriority && !showDiscountLabelBeforeGoodsNameHighPriority, // 价格后展示折扣标签
      showMultiColor: true,
      showBeltLabel: true,
      showRankingList: true,
      showStarComment: ['B', 'star'].includes(DetailStarReview?.p?.DetailStar),
      showDecisionAttr: detailKeyAttribute?.p?.DetailKeyAttribute === 'show',
      showSellingPoint: true,
      showEstimatedPriceReachThreshold: true,
      showQuickShip: true,
      showLocalSeller: true,
      showBadge: true,
      rankingLabelClickable: drankinglabelclick?.p?.drankinglabelclick === 'show',
      showPropertyUpsell: listattributeLabels?.p?.attributeLabels === 'Labels', // 属性类卖点标签
    }
    // 新商卡的价格样式由 newPrice abt实验控制
    const newPriceAbt = newPrice?.p?.newPrice === 'price'
    const isPaidUser = rootGetters['newProductDetail/isPaidUser']
    const priceStyleItemConfigByAbt = {
      // 命中新实验，增加以下商品项config
      showEstimatedPriceOnSale: true, // 新到手价样式（到手价-占用售价坑位）
      showEstimatedPriceReachThreshold: false, // 老到手价样式
      showSheinClubDiscountValue: false, // 不展示大号付费会员折扣标签
      showSheinClubDiscountValueAfterPrice: false, // 不展示小号付费会员折扣标签
      showSheinClubLabelInServicesLabel: true, // 付费会员标签展示在服务标签里面
      showSheinClubPriceOnSale: !!isPaidUser, // 是付费会员，就将价格展示为付费会员价格
      showSheinClubNewLabel: !!isPaidUser, // 展示新的付费会员文字标签
      showNewFlashNewLabel: true, // 展示新的新型闪购文字标签
    }
    newPriceAbt && Object.assign(itemConfigByAbt, priceStyleItemConfigByAbt)
    const res = {
      showTitle,
      showTitleTwoLine,
      showAddBagBtn,
      showHorseRaceLabel,
      addRankingLabelToSwiper,
      ...itemConfigByAbt
    }
    return res
  },
  // 新商卡多图 v3 额外配置
  newGoodsCardMultiConfig(state, getters, rootState, rootGetters) {
    // 基于二图，需要手动关闭一些
    const unifiedRecommend = rootGetters['newProductDetail/unifiedRecommend']
    const { multiGoodsRecItemsConfig = {} } = unifiedRecommend
    return multiGoodsRecItemsConfig
  },
  showSimilarItems(state, getters, rootState, rootGetters) {
    const isOutSite = rootState.newProductDetail.common.isOutSite
    const unifiedRecommend = rootGetters['newProductDetail/unifiedRecommend']
    const showSimilarItems = isOutSite
      ? false
      : ['one', 'two'].includes(unifiedRecommend?.similarRows)
    return showSimilarItems
  },
  // 不显示搭配购（老 + 新）
  hideGtlAndOutfit(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const isForwardCategory = rootGetters['newProductDetail/TopOther/isForwardCategory']
    const { outfitshowcate = 'all' } = fsAbt?.newoutfit?.param || {}
    const hiddenAll = outfitshowcate === 'onlygood' && !isForwardCategory // 命中abt仅正向品类展示搭配模块(老+新)
    if (hiddenAll) return true
    return false
  },
  // 不展示老搭配购
  hideOldGtlAndOutfit(state, getters) {
    const { hideGtlAndOutfit, showNewGtlAndOutfit } = getters || {}
    return hideGtlAndOutfit || showNewGtlAndOutfit
  },
  // 是否展示新搭配购（outfits + pdc gtl）
  showNewGtlAndOutfit(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { newoutfit } = fsAbt?.newoutfit?.param || {}
    if (getters.hideGtlAndOutfit) return false
    return newoutfit === 'new'
  },
  showBuyBoxSellers(state, getters, rootState, rootGetters) {
    const tsp = rootState.newProductDetail.coldModules.tsp?.tsp || {}
    return getters.needBuyBoxSellers && !tsp['4525']
  },
  needBuyBoxSellers(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { BuyBoxSwitch } = fsAbt?.BuyBoxSwitch?.param || {}
    const tsp = rootState.newProductDetail.coldModules.tsp?.tsp || {}
    return ['A', 'B', 'C'].includes(BuyBoxSwitch) && getters.buyBoxSameId 
  },
  buyBoxSameId(state, getters, rootState, rootGetters) {
    const tsp = rootState.newProductDetail.coldModules.tsp?.tsp || {}
    return tsp['4528'] || ''
  },
  isMainGoodsLowestPrice(state, getters, rootState, rootGetters) {
    // 1.是否是保留款商品, 是则结束
    const tsp = rootState.newProductDetail.coldModules.tsp?.tsp || {}
    const isReservedGood = tsp?.['3598'] || ''
    if(isReservedGood) return 0

    // 2. 是否命中buyboxABT, 否即结束
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { BuyBoxSwitch } = fsAbt?.BuyBoxSwitch?.param || {}
    const isBuyBoxSwitch = ['A', 'B', 'C'].includes(BuyBoxSwitch)
    if(!isBuyBoxSwitch) return 0

    // 3. 是否命中评论拼接ABT, 否即结束
    const { Comment_Group } = fsAbt?.Reviewchange?.param || {}
    const isCommentJoin = Comment_Group == 'A'
    if(!isCommentJoin) return 0

    // 4. 当前商品是否有同款组, TSP标签 4528有值，有同款组, 否即结束
    const buyBoxSameId = getters.buyBoxSameId
    if(!buyBoxSameId) return 0

    const mainGoodsId = rootGetters['newProductDetail/common/goodsId']
    const productItemsLanguage = rootGetters['newProductDetail/common/productItemsLanguage']
    const buyBoxRecInfo = rootState.newProductDetail?.common?.buyBoxRecInfo
    const { _newList } = getFormatBuyboxProductList(buyBoxRecInfo, false, productItemsLanguage)
    const lowerPriceGoodId = _newList?.find(item => item._serFormatInfo?.isLowerPice)?.goods_id || ''
    return lowerPriceGoodId == mainGoodsId ? 1 : 0
  },
  // 新搭配购数据
  newGtlEntryInfo(state, getters) {
    const { showNewGtlAndOutfit } = getters || {}
    if (!showNewGtlAndOutfit || !state?.newGtlAndOutfitInfo?.length) return null
    return state.newGtlAndOutfitInfo?.[0]
  },
  /**
   * 使用新组合购模块
   */
  isShowNewComboBuy(state, getters, rootState) {
    // return true
    if (!getters.isShowNewComboBuyAbt) return false

    return !!rootState.newProductDetail.common.hasValidComboBuyRecommend
  },
  isShowNewComboBuyAbt(state, getters, rootState, rootGetters) {
    // return true
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    return fsAbt?.buytogether?.p?.buytogether === 'show'
  },
  // 新组合购模块新样式
  isShowNewComboBuyStyleAbt(state, getters, rootState, rootGetters) {
    // return true
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    return fsAbt?.buytogether?.p?.buytogethershow === 'new'
  },
  // 新组合购模块屏效提升版样式
  isShowNewComboBuyScreenStyleAbt(state, getters, rootState, rootGetters) {
    // return true
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    return fsAbt?.buytogether?.p?.height === 'short'
  },
  // 新组合购模块橙色版屏效提升版样式
  isShowNewComboBuyOrangeScreenStyleAbt(state, getters) {
    const { isShowNewComboBuyScreenStyleAbt, isShowNewComboBuyStyleAbt } = getters || {}
    return isShowNewComboBuyScreenStyleAbt && isShowNewComboBuyStyleAbt
  },
  // 新组合购模块屏效提升版无规格选择器样式 需命中屏效提升
  isHideNewComboBuyScreenStyleSizeAbt(state, getters, rootState, rootGetters) {
    // return true
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    const { isShowNewComboBuyScreenStyleAbt } = getters || {}
    return isShowNewComboBuyScreenStyleAbt && fsAbt?.buytogether?.p?.chooseshow === 'none'
  },
  // 通用一行多图推荐场景商卡配置
  commonGoodsCardMultiConfig(state, getters, rootState, rootGetters) {
    const { commonInfo } = rootState.newProductDetail.coldModules
    const { IS_RW } = commonInfo || {}
    const newGoodsCardMultiConfig = getters.newGoodsCardMultiConfig
    const unifiedRecommendPrdConfig = getters.unifiedRecommendPrdConfig
    const commonGoodsCardMultiConfigV3 = getters.commonGoodsCardMultiConfigV3

    if (newGoodsCardMultiConfig.showDetailMultiNewCard) return commonGoodsCardMultiConfigV3

    return {
      ...recommendPublicProductItemConfig,
      showSheinClubDiscountValue: !IS_RW,
      hideFlashSaleCountDown: rootGetters['newProductDetail/PriceBelt/isFlashSaleCountDownAbtHide'],
      ...unifiedRecommendPrdConfig,
    }
  },
  // 通用一行多图推荐场景商卡配置，全量新商卡 todo
  commonGoodsCardMultiConfigV3(state, getters, rootState, rootGetters) {
    const { commonInfo } = rootState.newProductDetail.coldModules
    const { IS_RW } = commonInfo || {}
    const newGoodsCardMultiConfig = getters.newGoodsCardMultiConfig
    const newGoodsCardV3Config = getters.newGoodsCardV3Config
    const unifiedRecommendPrdConfig = getters.unifiedRecommendPrdConfig

    const { showSheinClubPriceOnSale } = newGoodsCardV3Config

    let _mergeConfig = {
      showSheinClubPriceOnSale
    }
    // 如果未命中新商卡，同时展示销量标签和折扣标签
    if (!newGoodsCardMultiConfig.showDetailMultiNewCard) {
      _mergeConfig = {
        ..._mergeConfig,
        showSalesLabel: true, // 销量标签
        showDiscountLabelAfterPrice: true, // 价格后面展示折扣标签
        showDiscountLabelBeforeGoodsName: true, // 商品名称前展示折扣标签
        showDiscountLabelBeforeGoodsNameHighPriority: true, // 商品名称前展示折扣标签，优先级最高
      }
    }

    return {
      ...recommendPublicProductItemConfig,
      showSheinClubDiscountValue: !IS_RW,
      hideFlashSaleCountDown: rootGetters['newProductDetail/PriceBelt/isFlashSaleCountDownAbtHide'],
      ...unifiedRecommendPrdConfig,
      ...newGoodsCardMultiConfig,
      ..._mergeConfig,
      showDetailMultiNewCard: true,
    }
  },

  // 商详一行多图场景配置，只显示价格
  // 收藏弹窗特殊场景
  commonGoodsCardOnlyPriceConfig(state, getters, rootState, rootGetters) {

    const isPaidUser = rootGetters['newProductDetail/isPaidUser']
    const isHitComplianceMode = rootGetters['newProductDetail/common/isHitComplianceMode']
    /**
      商品价格（不展示到手价）
      普通商品：原价、折扣价、闪购价（原价为黑色，折扣价为红色，闪购价为黄色）
      付费会员商品：用户是付费会员则付费会员价+会员小icon，用户是非付费会员则原价/折扣价+不展示会员标签（展示付费会员时候要用付费会员色 ）
      免费会员：不展示免费会员价
      新人闪购：展示标签价（也就是新人闪购价）（新人闪购的那种红色）
      特殊闪购：展示标签价（也就是特殊闪购价）（特殊闪购的那种红色）
     */

    const config = {
      showTitle: false,
      hideSoldOut: false,
      hideFlashSaleIcon: true, // 隐藏闪购图标
      hideLimitedTimeIcon: true, // 隐藏闪购计时图标
      showPlusSize: true,
      showLikeBtn: false,
      showSeriesBrand: true,
      useOwnClickExposeAnalysis: true, // 使用自己的点击曝光埋点
      disableMainimgJump: true,
      showPromoLabel: false, // src角标图片
      showNewFlashPriceOnSale: true, // 新人闪购价格
      hideDiscountBadge: true, // 隐藏闪购左上角折扣角标
      showNewDiscountBlet: false, // 是否展示新的闪购腰带
      hideRetailPrice: true, // 隐藏划线价
      showSheinClubPriceOnSale: !!isPaidUser, // 是付费会员，就将价格展示为付费会员价格
      showSheinClubDiscountValueAfterPrice: true, // 不展示小号付费会员折扣标签
      showSheinClubLabelInServicesLabel: false, // 付费会员标签展示在服务标签里面
      showDetailMultiNewCard: true, // 强制使用新商卡
      showCardLabelsContainer: false, // 隐藏卡片标签

      showEstimateTag: false, // 无到手价
      showEstimatedPriceReachThreshold: false, // 无到手价
      showEstimatedPriceOnSale: false, // 无到手价
      showEstimatedPrice: false, // 无到手价
      noNeedAnyEstimatedPriceTag: true, // 不显示到手价tag

      style: {
        discountLabel: 'flashSale',
        pricesNewline: true,
        salePriceFontSize: '12px',
        lineThroughPriceFontSize: '12px',
        lineThroughPriceScale: 0.833
      }
    }

    isHitComplianceMode && extendsComplianceModeConfig(config) // 合规模式特殊处理

    return config
  },

  /**
   * 商详一行一图场景配置（只有新商卡）
   */
  commonGoodsCardOnePicConfig(state, getters, rootState, rootGetters) {
    const isPaidUser = rootGetters['newProductDetail/isPaidUser']
    const newGoodsCardV3Config = getters.newGoodsCardV3Config
    const unifiedRecommendPrdConfig = getters.unifiedRecommendPrdConfig
    const unifiedRecommend = rootGetters['newProductDetail/unifiedRecommend']
    const { newGoodsCardBaseConfig } = unifiedRecommend
    const { showTitle, showTitleTwoLine } = newGoodsCardBaseConfig || {}

    return {
      ...recommendPublicProductItemConfig,
      ...newGoodsCardV3Config,
      // ...newGoodsCardMultiConfig,
      ...unifiedRecommendPrdConfig,
      showSheinClubLabelInServicesLabel: !!isPaidUser, // 付费会员标签展示在服务标签里面
      hideFlashSaleCountDown: rootGetters['newProductDetail/PriceBelt/isFlashSaleCountDownAbtHide'],
      showSheinClubDiscountValue: false, // 不展示大号付费会员折扣标签
      showSheinClubDiscountValueAfterPrice: false,  // 不展示小号付费会员折扣标签
      showEstimatedPriceReachThreshold: false, // 无到手价相关
      showEstimatedPriceOnSale: false, // 无到手价相关
      showMultiColor: true, // 显示色块
      // 全量新商卡
      showTitle,
      showTitleTwoLine,

      showQuickShip: true,
      showPromotion: true, // 促销
      showPromoLabel: true, // 大促标签
      showBestDealLabel: true, // 最佳折扣标签
      hideRetailPrice: true, // 隐藏划线价
      showPriceDiscountDesc: true,  // 历史低价标签、保留款跟价款标签
      showUserBehaviorLabel: true, // 用户行为标签
      showUserCommentKeywordsLabel: true, //用户评论关键词标签
      showDetailRecNewCard: true, // 一行一图只有新商卡
      showSellingPoint: true,
      showBeltLabel: false, // 展示腰带
    }
  },
  /**
   * 主商品的重叠类目id，string,必填 ,多个用逗号隔开，131场景使用
   */
  recommendMainGoodsOverlapIds(state, getters, rootState) {
    const tsp = rootState.newProductDetail.coldModules.tsp?.tsp || {}
    return tsp['4607'] || ''
  },
  // 新搭配推荐数据源用 ymals
  isOutfitShowYmalSource(state, getters, rootState, rootGetters) {
    const fsAbt = rootGetters['newProductDetail/fsAbt']
    return fsAbt.newoutfit?.param?.outfitsimilar === 'show-ymal'
  }
}

import { htmlDecode, template } from '@shein/common-function'
import { souceFormat } from 'public/src/pages/components/coupon/mall/utils'
import { mapRootGetters } from 'public/src/pages/goods_detail/utils/storeUtils.js'

const promotionInfoTextSel = (item, flashType, language, newFlashPromotion) => {
  const { SHEIN_KEY_PWA_26775: PRICE_DROP = 'Limited-Time Price Drop' } = language
  const filterID = [10, 11, 24]
  const dealerFallDownId = [31]
  const isLabel = filterID.includes(Number(item.typeId))
  if (item.typeId == 10 && [1, 2, 3, 4, 5, 6].includes(Number(flashType))) {
    let isBrandFlash = flashType == 6
    if (isBrandFlash) return language.SHEIN_KEY_PWA_29102
    const normalFlashStyle = [1, 5].includes(Number(flashType)) || ([4].includes(Number(flashType)) && newFlashPromotion)
    return normalFlashStyle
      ? language.SHEIN_KEY_PWA_25616
      : flashType == 2 ? language.SHEIN_KEY_PWA_21931 : language.SHEIN_KEY_PWA_21932
  } else if (isLabel) {
    return item.lable
  } else if (dealerFallDownId.includes(Number(item.typeId)) && item.promotionLogoType == 3) {
    // 命中商家直降活动: typeId = 31 + promotionLogoType 为 3
    return PRICE_DROP
  }
  return item.tips?.text
}

const paymentTextHandle = (item, language, withIcon) => {
  if(!item.rule) return ''
  const { min, random_min, random_max, value, value_amount, type } = item.rule || {}
  const hasThreshold = min?.amount > 0
  const txtOrIcon = withIcon && item?.icon ? `<img class="payment-icon" src=${item?.icon} />` : item?.title
  let text = ''
  if(hasThreshold){
    if(type == 2){ // 如果是随机立减
      text = template(random_min?.amountWithSymbol, random_max?.amountWithSymbol, min?.amountWithSymbol, txtOrIcon, language.SHEIN_KEY_PWA_30513)
    } else{
      // 具体折扣面额/折扣比例
      const saveVal = type == 0 ? value_amount?.amountWithSymbol : `${(value * 100).toFixed(0)}%`
      const languageRes = item.isBestPay ? language.SHEIN_KEY_PWA_30517 : language.SHEIN_KEY_PWA_30512
      text = template(saveVal, min?.amountWithSymbol, txtOrIcon, languageRes)
    }
  } else{
    if(type == 2){ // 如果是随机立减
      text = template(random_min?.amountWithSymbol, random_max?.amountWithSymbol, txtOrIcon, language.SHEIN_KEY_PWA_30510)
    } else{
      // 具体折扣面额/折扣比例
      const saveVal = type == 0 ? value_amount?.amountWithSymbol : `${(value * 100).toFixed(0)}%`
      const languageRes = item.isBestPay ? language.SHEIN_KEY_PWA_30515 : language.SHEIN_KEY_PWA_30508
      text = template(saveVal, txtOrIcon, languageRes)
    }
  }
  return text?.replace('{99}', txtOrIcon) // 产品 + app强诉求，要求把title的坑位 改成了${99}，导致web需要额外替换。
}

const percentFormat = num => {
  var percentNum = num + '%'
  return percentNum
}

const couponSubTitle = (item, language) => {
  const min = item.coupon_rule?.[0]?.min
  const max = item.coupon_rule?.[0]?.max
  if (max && max?.amountWithSymbol) {
    return (
      ' ' +
      template(
        min?.amountWithSymbol,
        max?.amountWithSymbol,
        htmlDecode({ text: language.SHEIN_KEY_PWA_18572 })
      )
    )
  }
  if (min?.amount > 0) {
    return ' ' + template(min?.amountWithSymbol, htmlDecode({ text: language.SHEIN_KEY_PWA_25377 }))
  } else {
    return ''
  }
}

export default {
  namespaced: true,
  state: {
    viewPromotionStatus: false, // 促销弹窗打开状态
    viewPromotionIsQuickAdd: false, // 快速加车唤起
    viewPromotionAddBag: 0, // 促销弹窗点击加车
    viewPromotionAnchoringExtraVoucher: false, // 首购礼金活动唤起促销弹窗 锚定到活动弹窗内首购礼金
    viewPromotionAnchoringNewUserCoupon: '' // 新人券唤起促销弹窗 锚定到对应新人券
  },
  mutations: {
    updateViewPromotionAddBag(state, payload) {
      state.viewPromotionAddBag = payload
    },
    updateViewPromotionStatus(state, payload) {
      state.viewPromotionStatus = payload.status
      state.viewPromotionIsQuickAdd = payload.isQuickAdd || false
      state.viewPromotionAnchoringExtraVoucher = payload.viewPromotionAnchoringExtraVoucher || false
      state.viewPromotionAnchoringNewUserCoupon = payload.viewPromotionAnchoringNewUserCoupon || ''
    }
  },
  getters: {
    getSeriesAndBrand(state, getters, rootState) {
      return rootState.newProductDetail.coldModules.brandAndSeries?.getSeriesAndBrand
    },
    /** 命中到手价abt */
    hitEstimatedAbt(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const abtValue = fsAbt?.EstimatedPrice?.p?.E_Price_Cal
      let includesAbts = ['Have_Threshold', 'Non_Threshold']
      const hitEstimatedAbt = includesAbts.includes(abtValue)
      return hitEstimatedAbt
    },
    originCouponList(state, getters, rootState, rootGetters) {
      const getEstimatedInfo = rootGetters['newProductDetail/common/getEstimatedInfo']
      const abtValue = getEstimatedInfo?.EstimatedPrice?.p?.E_Price_Cal
      let result = []
      if (['Have_Threshold', 'Non_Threshold'].includes(abtValue) || getEstimatedInfo?.isAb) {
        const newCouponList = getEstimatedInfo?.coupons || []
        const hasValue = getEstimatedInfo?.value // 存在有效到手价
        if (hasValue && newCouponList.length) {
          const optimalCouponList = getEstimatedInfo?.optimalCouponList || []
          optimalCouponList.forEach(coupon => {
            let idx = newCouponList?.findIndex(
              item => item.coupon_code === coupon?.coupon_code
            )
            if (idx > -1) {
              if(optimalCouponList.length > 1){
                if (getEstimatedInfo?.isAbCoexist || (getEstimatedInfo?.isAb && getEstimatedInfo?.isEstimatedstrategyNone)) {
                  if (optimalCouponList.length > 2) {
                    newCouponList[idx].is_stacked_best_coupon = true
                  } else {
                    newCouponList[idx].is_estimated_best_coupon = true
                  }
                } else {
                  newCouponList[idx].is_stacked_best_coupon = true
                }
              } else {
                newCouponList[idx].is_estimated_best_coupon = true
              }
            }
          })
        }
        let formatedNewCouponList = newCouponList.map(item => {
          return souceFormat(item)
        })
        result = formatedNewCouponList
      } else {
        result = rootGetters['newProductDetail/common/storeCouponListFormated']
      }
      return result
    },
    curCouponList(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      let result = getters.originCouponList
      let freestylecouponAbtValue = fsAbt?.itemfreeshipping?.p?.freestylecoupon || 'Notshow'
      const isProductFreeShipping = rootGetters['newProductDetail/ShippingEnter/isProductFreeShipping']
      const isPromotionFreeShipping = rootGetters['newProductDetail/ShippingEnter/isPromotionFreeShipping']
      if (
        freestylecouponAbtValue === 'Notshow' ||
        isProductFreeShipping ||
        isPromotionFreeShipping
      ) {
        result = result.filter(item => ![5, 6, 9].includes(Number(item.apply_for)))
      }

      return result
    },
    filterCouponList(state, getters) {
      const curCouponList = getters.curCouponList
      const filterCouponList = curCouponList.filter(
        item => item.coupon !== getters.newUserFilterCoupon?.coupon_code
      ) //被强化的券要从促销入口中剔除
      return  filterCouponList
    },
    couponListFormated(state, getters, rootState, rootGetters) {
      const couponList = rootGetters['newProductDetail/storeCouponList']
      let formated = couponList ? couponList.map((item) => {
        return souceFormat(item)
      }) : []
      return formated
    },
    hasPromotionEnterData(state, getters, rootState, rootGetters) {
      const realTimeReady = rootState.newProductDetail?.common?.realTimeReady
      if (!realTimeReady) return false
      const filterCouponList = getters.filterCouponList
      const drawerPaymentInfos = getters.drawerPaymentInfos
      const promotionInfoDesc = rootGetters['newProductDetail/common/promotionInfoDesc']
      const { isExtraVoucherTextType } = rootGetters['newProductDetail/common/extraVoucherPromotionInfo'] || {}
      return Boolean(
        (promotionInfoDesc.length && promotionInfoDesc[0].typeId) || filterCouponList?.length || drawerPaymentInfos?.length || getters.hasNew2Coupon || isExtraVoucherTextType
      )
    },
    showPromotionEnter(state, getters) {
      return Boolean(getters.hasPromotionEnterData)
    },
    onlyOnePromotion(state, getters, rootState, rootGetters){
      const drawerPaymentInfos = getters.drawerPaymentInfos
      const promotionInfoDesc = rootGetters['newProductDetail/common/promotionInfoDesc']
      const { isAb } = rootGetters['newProductDetail/common/getEstimatedInfo'] || {}
      const filterCouponList = getters.filterCouponList
      const { isExtraVoucherTextType  } = rootGetters['newProductDetail/common/extraVoucherPromotionInfo'] || {}
      return !isExtraVoucherTextType && !getters.hasNew2Coupon && !filterCouponList.length && !drawerPaymentInfos.length && promotionInfoDesc.length == 1 && !isAb
    },
    /**
     * 仅包含活动信息
     */
    onlyActivityDataList(state, getters, rootState, rootGetters) {
      const language = rootState.newProductDetail.coldModules.pageInfo.language
      const promotionInfoDesc = rootGetters['newProductDetail/common/promotionInfoDesc']
      const finalData = []
      const flashType = rootGetters['newProductDetail/common/flashType']
      const newFlashPromotion = rootGetters['newProductDetail/common/newFlashPromotion']

      promotionInfoDesc.forEach((item) => {
        const text = (promotionInfoTextSel(item, flashType, language, newFlashPromotion))?.trim?.()
        if (!text) return
        finalData.push({ text })
      })
      return finalData
    },
    /**
     * 仅包含优惠券信息
     */
    onlyCouponDataList(state, getters, rootState, rootGetters) {
      const language = rootState.newProductDetail.coldModules.pageInfo.language
      const filterCouponList = getters.filterCouponList
      const { isAb, discountValue, coupons, isAbCoexist, isEstimatedstrategyNone } = rootGetters['newProductDetail/common/getEstimatedInfo'] || {}
      if (isAb && !isAbCoexist && !isEstimatedstrategyNone) return [{ text: template(discountValue?.amountWithSymbol, (language.SHEIN_KEY_PWA_26358)) }]
      const finalData = []
      filterCouponList.forEach((item) => {

        const tempTextList = []
        const _couponRuleFirst = item.coupon_rule?.[0] || {}

        // 是无门槛券
        const _isWithoutThreshold = _couponRuleFirst?.min?.amount && Number(_couponRuleFirst?.min?.amount) === 0 && !_couponRuleFirst?.max?.amountWithSymbol
        const _LANG_KEY = _isWithoutThreshold && language.SHEIN_KEY_PWA_27117 || _couponRuleFirst?.coupon_gift_id && language.SHEIN_KEY_PWA_18334 || language.SHEIN_KEY_PWA_15816

        const _pushData = (str, useTrim = true) => {
          const _str = useTrim ? str?.trim?.() : str
          if (!_str) return
          tempTextList.push(_str)
        }
        if (item?.apply_for == 101) {
          let discountValue = coupons.find(estimatedCoupons => estimatedCoupons.apply_for == 101)?.discountValue.amountWithSymbol
          _pushData(template(discountValue, language.SHEIN_KEY_PWA_26358))
        } else if (['5', '6'].includes(item?.apply_for) || (item?.apply_for == '9' && item?.shipping_discount_type == 0)) {
          let text = language.SHEIN_KEY_PWA_15807
          if(_isWithoutThreshold){
            text += (' ' + language.SHEIN_KEY_PWA_27118)
          }
          _pushData(text)
        } else if (item?.apply_for == '9' && item?.shipping_discount_type == 1){
          const money = item?.coupon_type_id == 1 ? _couponRuleFirst?.value?.amountWithSymbol : percentFormat(_couponRuleFirst?.value?.amount)
          _pushData(money + ' ' + language.SHEIN_KEY_PWA_29122)
        } else if (item?.coupon_type_id == 1) {
          _pushData(template(
            _couponRuleFirst?.value?.amountWithSymbol,
            htmlDecode({
              text: _LANG_KEY,
            })
          ))
        } else {
          _pushData(template(
            percentFormat(_couponRuleFirst?.value?.amount),
            htmlDecode({
              text: _LANG_KEY,
            })
          ))
        }

        _pushData(couponSubTitle(item, language), false)
        tempTextList.length && finalData.push({ text: tempTextList.join('') })
      })

      return finalData
    },
    // 支付方式列表
    onlyPaymendInfos(state, getters, rootState, rootGetters) {
      const language = rootState.newProductDetail.coldModules.pageInfo.language
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const showAbt = fsAbt?.paydiscountshowdetail?.p?.paydiscountshowdetail
      if(showAbt !== 'Show') return  { bestPay: null, otherPay: [] }
      const paymentInfoContent = rootGetters['newProductDetail/paymentInfoContent'] || {}
      const getEstimatedInfo = rootGetters['newProductDetail/common/getEstimatedInfo']
      let { iconsList = [], sortedPayments = [], paymentInfos = [] } = paymentInfoContent || {}
      // 1.根据sortedPayments 优先级排序，查不到优先级结果的 过滤不展示
      sortedPayments.sort((a, b)=>a.sort - b.sort)
      let sortedFinalList = sortedPayments.map(item => {
        let currPayment = paymentInfos.find(payment => {
          return payment?.all_payment?.includes(item.paymentCode)
        })
        if(!currPayment) return null
        // 根据iconsList 替换icon, 和支付方式列表用‘paymentCode’去做遍历匹配icon
        let cccInfo = iconsList.find(info => {
          return info.paymentCode === item.paymentCode
        })
        return {
          ...item,
          icon: cccInfo?.icon || '',
          rule: currPayment.rules?.[0],
        }
      }).filter(item => Boolean(item) && (item.title || item.icon))
      
      let bestPay = null
      let otherPay = []
      if(!sortedFinalList?.length) return { bestPay, otherPay }
      // 2.判断是否存在当前到主价格可达门槛的支付方式, 将首先满足门槛作为优先级最高的排在促销楼层首位，定义为最佳支付优惠
      // 取商品的商详主价格，含义有到手价取到手，无到手价取实际活动价/售价
      let mainPrice = Number(getEstimatedInfo?.value?.amount || rootGetters['newProductDetail/Price/salePrice']?.salePriceAmount)
      let bestIndex = sortedFinalList.findIndex(it => {
        if(it.rule?.type == 2) return false
        const threshold = Number(it.rule?.min?.amount || 0)
        return mainPrice > threshold
      })
      if(bestIndex > -1){
        bestPay = sortedFinalList?.[bestIndex] || null
        bestPay.isBestPay = true
        bestPay.text = paymentTextHandle(bestPay, language, true)
        // 其他非最佳支付方式，放在促销楼层后面
        const otherList = [...sortedFinalList.slice(0, bestIndex), ...sortedFinalList.slice(bestIndex + 1)]
        otherPay = otherList.map(item => ({
          ...item,
          text: paymentTextHandle(item, language)
        }))
      }else{
        otherPay = sortedFinalList.map(item => ({
          ...item,
          text: paymentTextHandle(item, language)
        }))
      }
      return { bestPay, otherPay }
    },
    // 促销弹窗展示的支付方式列表 - 新增子模块“Discounts For Payment“
    drawerPaymentInfos(state, getters, rootState) {
      const language = rootState.newProductDetail.coldModules.pageInfo.language
      let { bestPay, otherPay } = getters.onlyPaymendInfos || {}
      const bestPayCopy = Object.assign({}, bestPay, { isBestPay: false })
      bestPayCopy.text = paymentTextHandle(bestPayCopy, language, true)
      otherPay = otherPay.map(item => { 
        return {
          ...item,
          text: paymentTextHandle(item, language, true)
        }
      })
      return [bestPayCopy, ...otherPay].filter(it => it?.text)
    },
    /**
     * 活动和优惠券信息集合
     */
    activityMergeCouponDataList(state, getters, rootState, rootGetters) {
      // ab 价
      const language = rootState.newProductDetail.coldModules.pageInfo.language
      const { isAb, discountValue, isEstimatedstrategyNone } = rootGetters['newProductDetail/common/getEstimatedInfo'] || {}
      if (isAb && !isEstimatedstrategyNone) return [{ text: template(discountValue?.amountWithSymbol, (language.SHEIN_KEY_PWA_26358)) }]
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const { onlyActivityDataList, onlyCouponDataList, onlyPaymendInfos } = getters
      const rankAbt = fsAbt?.Promotionrankdetial?.p?.Promotionrankdetial
      const { bestPay, otherPay } = onlyPaymendInfos || {}
      const oldOrder = [bestPay, ...onlyActivityDataList, ...otherPay, ...onlyCouponDataList]
      const newOrder = [bestPay, ...onlyCouponDataList, ...onlyActivityDataList, ...otherPay]
      const finalList = rankAbt === 'New' ?  newOrder : oldOrder

      const { isExtraVoucherTextType, extraVoucherTextTypeTexts, discountAmountWithSymbol } = rootGetters['newProductDetail/common/extraVoucherPromotionInfo'] || {}
      if(isExtraVoucherTextType){
        finalList.unshift({
          text: template(discountAmountWithSymbol, extraVoucherTextTypeTexts.extraVoucher),
          exposeConfig: {
            daId: '1-6-4-93', extraData: { location: 1 }
          },
          tapConfig: { 
            daId: '1-6-4-94', extraData: { location: 1 }
          },
          isSpecial: true
        })
      }

      if(getters.hasNew2Coupon) {
        const newUserCouponInfo = rootGetters['newProductDetail/common/newUserCouponInfo']
        const { newUserDiscount } = newUserCouponInfo || {}  
        finalList.unshift({
          text: template(newUserDiscount, language.SHEIN_KEY_PWA_31230),
          exposeConfig: {
            daId: '1-6-1-160'
          },
          tapConfig: {
            daId: '1-6-1-159',
            extraData: { operation_results: 1 }
          },
          isSpecial: true
        })
      }
      return finalList
    },
    isShowPromo(state, getters, rootState, rootGetters) {
      const drawerPaymentInfos = getters.drawerPaymentInfos
      const curCouponList = getters.curCouponList
      const { isExtraVoucherTextType } = rootGetters['newProductDetail/common/extraVoucherPromotionInfo'] || {}
      if (curCouponList?.length || drawerPaymentInfos?.length || isExtraVoucherTextType) return true
      const promotionInfoDesc = rootGetters['newProductDetail/common/promotionInfoDesc']
      const flashType = rootGetters['newProductDetail/common/flashType']
      const newFlashPromotion = rootGetters['newProductDetail/common/newFlashPromotion']
      const newFlashPromotionPriceWithSymbol = newFlashPromotion?.price?.amountWithSymbol || ''
      return Boolean(
        promotionInfoDesc.length &&
          promotionInfoDesc.find((i) => {
            if (+i.typeId === 10 && ([1, 5].includes(flashType) || (flashType == 4 && newFlashPromotionPriceWithSymbol))) return true // Normal Flash
            if (+i.typeId === 10 && !newFlashPromotion) return false // Special Flash
            return ![8, 12, NaN].includes(+i.typeId)
          })
      )
    },
    newUserFilterCoupon(state, getters, rootState, rootGetters) {
      const newUserCouponInfo = rootGetters['newProductDetail/common/newUserCouponInfo']
      const { newUserCoupon } = newUserCouponInfo || {}
      const isFilterNewCoupon = getters.hitEstimatedAbt && newUserCoupon
      return isFilterNewCoupon ? newUserCoupon : null
    },
    hasNew2Coupon(state, getters, rootState, rootGetters){
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const abtNewCoupon = fsAbt?.NewCoupon?.p?.NewCoupon
      return (getters.newUserFilterCoupon && abtNewCoupon === 'New2')
    }
  }
}

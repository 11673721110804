import { template } from '@shein/common-function'
export default {
  namespaced: true,
  state: {},
  getters: {
    topInfoBase(state, getters, rootState) {
      const { productInfo = {}, commonInfo = {}, pageInfo } = rootState.newProductDetail.coldModules
      const { host_rp, langPath_rp, IS_RW, SiteUID, GB_cssRight, PUBLIC_CDN_rp } = commonInfo
      return {
        language: pageInfo?.language,
        PUBLIC_CDN_rp,
        GB_cssRight,
        SiteUID,
        IS_RW,
        host_rp,
        langPath_rp,
        detail: productInfo?.detail,
      }
    },
    /** 价格显示位置 */
    // abtPriceLocatTop(state, getters, rootState, rootGetters) {
    //   const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   return fsAbt?.PriceLocat?.p?.PriceLocat === 'A'
    // },
    designerInfo(state, getters, rootState) {
      return rootState.newProductDetail.coldModules.productInfo?.designerInfo || {}
    },
    isShowDesignerInfo(state, getters) {
      // const fsAbt = rootGetters['newProductDetail/fsAbt']
      return getters.designerInfo?.designer_name
      // return (
      //   fsAbt?.sheinxreinforce?.p?.sheinxreinforce === 'new2' && getters.designerInfo?.designer_name
      // )
    },
    // 最低价提示相关
    lowestPriceTipsConfig(state, getters, rootState, rootGetters) {
      const { pageInfo, tsp = {} } = rootState.newProductDetail.coldModules
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const { hitLowestPrice = '' } = tsp?.tspConfigCombo || {}
      const { pricestatues = {} } = fsAbt
      const { lowestprice } = pricestatues?.p || {}
      const { language } = pageInfo
      if (!hitLowestPrice) return { show: false }
      return {
        show: lowestprice == 'show',
        hitLowestPrice,
        contentText: template(hitLowestPrice, language.SHEIN_KEY_PWA_26774),
      }
    },
    /**
     * @returns { Object }
     *  @param { Boolean } show 是否显示
     *  @param { String } text 文案内容
     *  @param { String } type 文案类型 
     *                          [retentionBelt 保留款腰带 / lowestBelt x天最低价腰带 / followBelt 近期低价腰带] 
     *                          [retentionText 保留款文本 / lowestText x天最低价文本 / followText 近期低价文本] 
     */
    bestDealConfig(state, getters, rootState, rootGetters) {
      // 这里聚合了 保留款｜x天最低价｜近期低价  优先级： 「保留款」>「x天最低价」 >「近期降价」
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const isPerformance = rootGetters['newProductDetail/isPerformance']
      const retentionPositionConfig = rootGetters['newProductDetail/common/retentionPositionConfig']
      const isShowFollowBelt = rootGetters['newProductDetail/common/isShowFollowBelt']
      const isSoldOut = rootGetters['newProductDetail/common/isSoldOut']
      const { goodsReady } = rootState.newProductDetail.common

      const { lowestPriceTipsConfig } = getters
      const { pageInfo } = rootState.newProductDetail.coldModules
      const { pricestatues = {} } = fsAbt
      const { language } = pageInfo
      const { showtype = 'banner' } = pricestatues?.p || {} 
      if (isSoldOut || (!goodsReady && isPerformance)) return { show: false }
      let text = '',
          tipsType = '', // 保留款 2｜x天最低价 1｜近期低价 3
          rightText = '',
          type = null
      if (retentionPositionConfig.show) {
        text = retentionPositionConfig.contentText
        rightText = retentionPositionConfig.rightText
        tipsType = 2
        type = 'retention'
      } else if (lowestPriceTipsConfig.show) {
        text = lowestPriceTipsConfig.contentText
        tipsType = 1
        type = 'lowest'
      } else if (isShowFollowBelt) {
        text = language.SHEIN_KEY_PWA_25452
        tipsType = 3
        type = 'follow'
      } else return { show: false }
      
      if ((showtype == 'banner' || !showtype) && tipsType !== 1) type = type + 'Belt'
      if (showtype == 'pricebottom' || tipsType == 1) type = type + 'Text'

      return {
        text,
        type,
        tipsType,
        rightText
      }
    },
    promotionBeltInfo(state, getters, rootState) {
      return rootState.newProductDetail.hotModules.promotion.promotionBeltInfo || {}
    },
    isEvolushein(state, getters, rootState, rootGetters){
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const showEvolushein = fsAbt?.evolushein?.p?.evolushein === 'show'
      const { evoluColorList = {}, detail: { goods_sn = '' } = {} } = rootState.newProductDetail.coldModules.productInfo
      return !!evoluColorList?.[goods_sn] && showEvolushein
    },
    titleSellPointsList(state, getters, rootState, rootGetters) {
      const { titlesellpoint, Guarantee } = rootGetters['newProductDetail/fsAbt'] || {}
      const titlesellpointAbt = titlesellpoint?.p?.titlesellpoint || ''
      if(!['plana', 'planb'].includes(titlesellpointAbt)) return []
      
      // 环保标识evoluSHEIN
      const isEvolushein = getters.isEvolushein

      const commentOverView  = rootGetters['newProductDetail/commentOverView'] || {}
      // 评分
      const commentRankAverage = commentOverView?.comment_rank_average || ''
      const TAG_ID = '3726' // 商品2年内5星好评
      const userBehaviorLabels = rootState.newProductDetail.coldModules?.ccc?.AtmosphereFlow?.detailAndQuickCommonView?.userBehaviorLabels || []
      // 好评数
      const positiveReviews = userBehaviorLabels.find(i => i.labelId === TAG_ID)?.labelLang || ''
      // 免费退货
      const { freeReturnPolicy } = rootState.newProductDetail.coldModules?.shipping || {}
      // 安全购
      const showShippingGuarantee = Guarantee?.p?.Guarantee === 'show'
      // 品牌100正品
      const isBrandHasTargetTag = rootGetters['newProductDetail/common/isBrandHasTargetTag']

      /* 
        环保：evoluSHEIN
        保障：免费退换货、放心购、品牌正品
        评价：评分、好评数
      */
      let sellPoints = [
        { key: 'evolushein', value: 'evoluSHEIN', languageKey: '', colorClass: 'golden', show: isEvolushein, tagType: 'evolushein' },
        { key: 'freeReturn', value: '', languageKey: 'SHEIN_KEY_PWA_31109', colorClass: 'green', show: freeReturnPolicy, tagType: 'free return' },
        { key: 'guarantee', value: '', languageKey: 'SHEIN_KEY_PWA_27597', colorClass: 'green', show: showShippingGuarantee, tagType: 'shopping security' },
        { key: 'brandTag', value: '', languageKey: 'SHEIN_KEY_PWA_26778', colorClass: 'blue', show: isBrandHasTargetTag, tagType: '100% authentic' },
      ]
      
      const commentPoint = [
        { key: 'rankAverage', value: commentRankAverage, languageKey: '', colorClass: 'golden', show: !!commentRankAverage, tagType: 'rate' },
        { key: 'positiveReviews', value: positiveReviews, languageKey: '', colorClass: 'golden', show: !!positiveReviews, tagType: 'positive reviews' },
      ]
      if(titlesellpointAbt === 'plana') {
        // titlesellpoint=plana时，展示顺序：evoluSHEIN环保、评分、好评数、免费退换货、放心购、品牌正品
        sellPoints.splice(1, 0, ...commentPoint)
      }else if(titlesellpointAbt === 'planb'){
        // titlesellpoint=planb时，展示顺序：evoluSHEIN环保、免费退换货、放心购、品牌正品、好评数、评分
        sellPoints.push(...commentPoint.reverse())
      }

      let filterData = sellPoints.filter(i => i.show)
      
      return filterData
    }
  },
}

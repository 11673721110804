const mutations = {
  resetLocals(state) {
    if (state?.locals) return
    if (typeof window === 'undefined') return
    const { WEB_CLIENT, IS_RW, host, lang, langPath, currency, GB_cssRight, LAZY_IMG, hasTopBanner, hasPolicyBanner, policyBannerPlaceholder, showBranch, RESOURCE_SDK } = gbCommonInfo
    state.locals = { WEB_CLIENT, IS_RW, host, lang, langPath, currency, GB_cssRight, LAZY_IMG, hasTopBanner, hasPolicyBanner, policyBannerPlaceholder, showBranch, RESOURCE_SDK }
  },
  assignState (state, payload) {
    Object.assign(state, payload)
  },
  assignStateByCateType (state, payload) {
    Object.assign(state[payload.cateType], payload)
  },
  assignStateCateExpand (state, payload) {
    Object.assign(state['cateExpand'], payload)
  },
  assignStateSelectedOneCate (state, payload) {
    Object.assign(state['selectedOneCate'], payload)
  },
  /*
  * 切块push数据
  */ 
  // dealingTwoCate (state, { twoCategory, cateType }) {
  //   if (!Array.isArray(twoCategory)) {
  //     throw new Error('twoCategory must be Array')
  //   }
    
  //   const sliceCount = 1
  //   Vue.set(state[cateType], 'twoCategory', twoCategory.slice(0, sliceCount)) // 先渲染一块
  //   deepPushData(twoCategory.slice(sliceCount), sliceCount)

  //   function deepPushData(list, sliceCount) {
  //     if (list.length === 0) {
  //       state[cateType].twoCategoryInitDone = true
  //       return
  //     }
  //     requestAnimationFrame(() => {
  //       const goodsChunk = list.slice(0, sliceCount)
  //       Vue.set(state[cateType], 'twoCategory', state[cateType].twoCategory.concat(goodsChunk))
  //       deepPushData(list.slice(sliceCount), sliceCount)
  //     })
  //   }
  // },
}

export default mutations

const RIGHT_CATEGORY_WIDTH = 7.2267 - 0.32 * 2
const SIDE_RIGHT_CATEGORY_WIDTH = 8 - 0.32 * 2

const state = {
  pageCode: 'pwaCategoryPage',
  isNew: false, // 是否新接口
  isPreview: false,
  locals: null,
  ssrPageType: '',
  language: {},
  bffLanguages: {},
  categoryCrowdInfo: {},
  allTabAbt: {},
  isAllTabAutoMap: 0,
  cateType: 'cat', // 监听'rootStatus.show_category', true为'sidecat', false为'cat'
  dynamicCates: [],
  cateExpand: {},
  expandRearFirstLevel: '',
  expandFirstLevelId: undefined,
  selectedOneCate: {},
  crowdChannel: '', // 人群频道
  categoryData: {}, // category数据
  categoryDataListByOrder: {}, // category数据按照order排序
  categoryFreeshippingCcc: {},
  // category 和 sidecategory差异化数据。用于内部父子组件状态共享
  cat: {
    // channelKey: '',
    // oneCategoryKey: '',
    // storageType: '',
    active: {
      channel: '',
      channelIndex: '',
      oneCate: '',
      isNew: undefined
    },
    oneCategory: [],
    oneCategoryExpand: {},
    twoCategory: [],
    rightCateWidth: RIGHT_CATEGORY_WIDTH,
    tabLoadingStatus: false,
    oneCateLoadingStatus: false,
    twoCateLoadingStatus: false,
    initCateData: false,
    // twoCategoryInitDone: false,
  },
  sidecat: {
    channelKey: '',
    oneCategoryKey: '',
    storageType: '',
    active: {
      channel: '',
      channelIndex: '',
      oneCate: '',
      isNew: undefined
    },
    oneCategory: [],
    oneCategoryExpand: {},
    twoCategory: [],
    rightCateWidth: SIDE_RIGHT_CATEGORY_WIDTH,
    tabLoadingStatus: true,
    oneCateLoadingStatus: true,
    twoCateLoadingStatus: false,
    initCateData: false,
    // twoCategoryInitDone: false,
  },
  // 中间层融合数据
  bffCat: {
    channelKey: '',
    oneCategoryKey: '',
    storageType: '',
    activeChannel: {
      channel: '', // 选中的频道
      channelIndex: '', // 选中的频道索引
      oneCate: '', // 选中的一级分类索引
    },
    categoryContainer: { // 缓存容器
      oneCategoryData: {},
      twoCategoryData: {},
      batchTwoCategoryData: {},
    },
    topNav: [], // 顶部tab 数据
    oneCategory: [], // 一级分类， 左侧数据
    twoCategory: [], // 二级分类， 右侧数据
    rightCateWidth: SIDE_RIGHT_CATEGORY_WIDTH,
    tabLoadingStatus: true, // tab 是否loading
    oneCateLoadingStatus: true, // 左侧内容区是否loading
    twoCateLoadingStatus: false, // 右侧内容是否loading
    initCateData: false,
  }
}
export default state
